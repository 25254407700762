import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

// Drawer opening delay (ms)
const DRAWER_DELAY = 350;
const DRAWER_WIDTH = 350;

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Is the drawer currently open? */
  open: boolean;
}

const DrawerBase = (props: IProps) =>
  <div className={props.className}>
    <Pane>
      {props.children}
    </Pane>
  </div>

//
// This pane always has the full width of the drawer. 
//
const Pane = styled.div`
  position: absolute;
  right: 0;
  width: ${DRAWER_WIDTH}px;
  top: 0;
  bottom: 0;
  background: #fff;
  border-right: solid 1px #aaa;
  box-sizing: border-box;
`

//
// The drawer itself adjusts its width when opening.
//
const Drawer = styled(DrawerBase)`
  position: absolute;
  z-index: 300;
  top: 0;
  bottom: 0;
  left: 80px;
  width: ${p => p.open ? DRAWER_WIDTH : 0}px;
  transition: width ease-in-out ${DRAWER_DELAY}ms;
  overflow: hidden;
`

export { Drawer }
