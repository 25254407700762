import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { GeoJsonObject } from 'geojson';

import { App } from '../../App';

//
// Structure of a static map request to mapbox:
// https://api.mapbox.com/styles/v1    - Root
// /{username}/{style_id}              - Style user and name, e.g. "llenv/tg9fdg9fg"
// /static
// /{overlay}                          - geojson, marker, custommarker or path
// /{lon},{lat},{zoom},{bearing},{pitch}|{bbox}|{auto}
// /{width}x{height}{@2x}
//

interface IProps {
  className?: string;
  /** Map with in pixels */
  width: number;
  /** Map height in pixels */
  height: number;
  /** Render the static map at a @2x scale factor for high-density displays. */
  hires?: boolean;
  /** Mapbox style to use, 
   *  e.g. "longlineenvironment/clb14ar2y00hw14oh9ii2zp05" 
   */  
  style: string;
  /**
   * Map location. This can either be an object containing lat/lng, zoom, 
   * bearing and pitch, _or_ a bounding box of `[minLon, minLat, maxLon, maxLat]`
   * _or_ `auto`, in which case the map area will match any overlay.
   */
  location: { latitude: number, longitude: number, zoom: number, bearing?: number, pitch?: number } 
            | [number, number, number, number] 
            | "auto";
  /**
   * Overlay to be shown on map.
   */
  overlay?: GeoJsonObject;
  /**
   * Padding
   */
  padding?: number[];
}

const StaticMapBase = (props: IProps) => {

  //
  // Convert map specification to a URL and return it.
  //
  const getURL = (): string => {
    // Convert area specification to string:
    let area = "";
    // Case: location is a 4-number array (a bounding box).
    if(Array.isArray(props.location)) {
      // Round coordinates to 6 decimal places to shorten URL. Excessive 
      // accuracy is not needed.
      area = JSON.stringify(props.location.map(n => Math.round(n * 1000000) / 1000000));
    } 
    // Case: location is an object with lat, lng, zoom, bearing, pitch.
    else if(typeof props.location === 'object') {
      const loc = props.location as any;
      area = `${loc.longitude},${loc.latitude},${loc.zoom},${loc.bearing ?? 0},${loc.pitch ?? 0}`;
    } 
    // Case: location is a string ("auto").
    else {
      area = props.location as string;
    }

    let url = "https://api.mapbox.com/styles/v1/";
    url += props.style + "/";
    url += "static/";
    if(props.overlay) {
      url += `geojson(${encodeURIComponent(JSON.stringify(props.overlay))})/`;
    }
    url += area + "/";
    url += props.width + "x"  + props.height + (props.hires ? "@2x" : "")
    url += "?access_token=" + App.config.mapboxKey;
    url += "&logo=false&attribution=false";
    if(props.padding) url += `&padding=${props.padding.join(",")}`;

    return url;
  }

  return (
    <img className={props.className} src={getURL()}/>
  );
}

const StaticMap = styled(StaticMapBase)` 
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
`

export { StaticMap }
