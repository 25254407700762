import { Url } from "../../helpers/Url";
import { Api } from "../Api";
import { Campaign, ListResponse, Parameter, Parametercategory, System } from "../models";

class ParameterApi {
  /**
   * Retrieve list of parameters, given one or more systems and optionally a
   * campaign.
   */
  static list(access_token: string, systems: System[], campaign: Campaign, signal?: AbortSignal): Promise<Parameter[]> {
    const url = new Url("parameters");
    url.append("systems", systems.map(s => s.id));
    if(campaign) url.append("campaign", campaign.id);

    return Api.Get<Parameter[]>(url.toString(), access_token, signal);
  }

  /**
   * Retrieve list of parameter categories, sorted by name (A-Z).
   */
  static async listCategories(access_token: string, signal?: AbortSignal): Promise<ListResponse<Parametercategory>> {
    const res = await Api.Get<ListResponse<Parametercategory>>("parametercategories", access_token, signal);
    res.data.sort((a, b) => a.name > b.name ? 1 : 0);
    return res;
  }
}

export { ParameterApi }
