import type { SymbolLayer } from 'react-map-gl';

const VisualizationSymbolLayer: SymbolLayer = {
  id: 'visualization-symbols',
  type: 'symbol',
  layout: {
    'text-field': ['get', 'v'],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12    
  }
};

export { VisualizationSymbolLayer }
