import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps { 
  /** @ignore */
  className?: string;
}

const DrawerNodataBase = (props: IProps) =>
  <div className={props.className}>
    No data
  </div>

const DrawerNodata = styled(DrawerNodataBase)`
  margin: 16px;
  padding: 16px 8px 16px 8px;
  background: #fff;
  color: #888;
  text-align: center;
  border-radius: 4px;
  border: solid 1px #eee;
  user-select: none;
`

export { DrawerNodata }