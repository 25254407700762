import type { CircleLayer } from 'react-map-gl';
import { MARKER_FILL_COLOR } from './ClusterConstants';

const MarkersCircleLayer: CircleLayer = {
  id: "markers-circles",
  type: "circle",
  paint: {
    "circle-radius": 9,
    "circle-color": MARKER_FILL_COLOR
  }
};

export { MarkersCircleLayer }
