import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Gravatar } from '@independent-software/typeui/controls';

import { IconBar } from './IconBar';
import { Map } from '../map/Map';
import { Drawer } from './Drawer';
import { DataArea } from './DataArea';
import { Splitter, TSplitterState } from './Splitter';
import { BigIcon } from './BigIcon';
import { Logo } from './Logo';
import { BarButton } from './BarButton';
import { CampaignsTray, ParametersTray, SystemsTray, UserTray } from '../components/trays';
import { FilterContext } from '../contexts/FilterContext';
import { SelectSystemHint } from './SelectSystemHint';

interface IProps {
  /** @ignore */
  className?: string;
}

enum TrayTypes {
  TrayParameters,
  TrayCampaigns,
  TraySystems,
  TrayUser,
}

const ScreenBase = (props: IProps) => {
  const filter = React.useContext(FilterContext);
  // Is the drawer currently open?
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  // Which drawer content is displayed?
  const [trayIndex, setTrayIndex] = React.useState<number>(null);
  // What is the splitter state?
  const [splitterState, setSplitterState] = React.useState<TSplitterState>('closed');

  const handleOpenDrawer = (index: number) => {
    setTrayIndex(index);
    setDrawerOpen(true);
  }

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  }

  React.useEffect(() => {
    // Listen for document-wide zoomtoSystem event when component mounts.
    document.addEventListener('zoomToSystem', handleZoomToSystem);
    return () => {
      // Clean up document-wide zoomToSysetm event when component unmounts.
      document.removeEventListener('zoomToSystem', handleZoomToSystem);
    }
  }, [])

  //
  // Respond to zoomToSystem event by closing the drawer.
  //
  const handleZoomToSystem = (e: CustomEvent) => {
    setDrawerOpen(false);
  }  

  return (
    <div className={props.className}>
      <IconBar>
        <div>
          <Logo/>
          <div style={{height: "20px"}}></div>
          <BarButton text="Parameters" active={trayIndex == TrayTypes.TrayParameters && drawerOpen} onClick={() => handleOpenDrawer(TrayTypes.TrayParameters)}>
            <BigIcon name="parameters"/>
          </BarButton>
          <BarButton text="Campaigns"active={trayIndex == TrayTypes.TrayCampaigns && drawerOpen} onClick={() => handleOpenDrawer(TrayTypes.TrayCampaigns)}>
            <BigIcon name="campaigns"/>
          </BarButton>
          <BarButton text="Systems" active={trayIndex == TrayTypes.TraySystems && drawerOpen} onClick={() => handleOpenDrawer(TrayTypes.TraySystems)} count={filter.systems.length} 
             annotation={filter.systems.length == 0 && <SelectSystemHint/>}>
            <BigIcon name="systems"/>
          </BarButton>
        </div>

        <BarButton active={trayIndex == TrayTypes.TrayUser && drawerOpen} onClick={() => handleOpenDrawer(TrayTypes.TrayUser)}>
          <Gravatar email="hello@world.com"/>
        </BarButton>

      </IconBar>

      <Drawer open={drawerOpen}>
        <ParametersTray open={trayIndex == TrayTypes.TrayParameters}/>
        <CampaignsTray open={trayIndex == TrayTypes.TrayCampaigns}/>
        <SystemsTray open={trayIndex == TrayTypes.TraySystems}/>
        <UserTray open={trayIndex == TrayTypes.TrayUser}/>
      </Drawer>
      
      <Splitter
        left={<Map/>}
        right={<DataArea/>}
        state={splitterState}
        onState={(state) => setSplitterState(state)}
        onClick={handleCloseDrawer}
      /> 
    </div>
  );
}

const Screen = styled(ScreenBase)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
`

export { Screen }

