import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { FilterContext } from '../../../contexts/FilterContext';
import { ResultCount } from '../../../api/models';
import { ResultApi } from '../../../api/services/ResultApi';
import { Bucketer } from '../Area/Bucketer';
import { Button, Icon, Label } from '@independent-software/typeui/controls';
import { Grid } from './Grid';
import { format } from 'date-fns';

const ZOOM_FACTOR = 1.1;

interface IProps {
  className?: string;
}

const ResultGridBase = (props: IProps) => {
  const filter = React.useContext(FilterContext);
  const [counts, setCounts] = React.useState<ResultCount[]>([]);
  const [zoom, setZoom] = React.useState<number>(1);

  React.useEffect(() => loadCounts(), [filter.parameter, filter.systems]);

  const loadCounts = () => {
    if(filter.parameter == null || filter.systems.length == 0) {
      setCounts([]);
      return;
    }

    ResultApi.getCounts(null, filter.systems, filter.parameter)
    .then(res => {
      setCounts(res);
    });
  }

  const handleZoomIn = () => {
    setZoom(zoom * ZOOM_FACTOR);
  }

  const handleZoomOut = () => {
    setZoom(zoom / ZOOM_FACTOR);
  }

  const dayElements = React.useMemo(() => Bucketer.createDayBuckets(counts), [counts]);
  const monthElements = React.useMemo(() => Bucketer.createMonthBuckets(counts), [counts]);
  const yearElements = React.useMemo(() => Bucketer.createYearBuckets(counts), [counts]);
  const decadeElements = React.useMemo(() => Bucketer.createDecadeBuckets(counts), [counts]);

  // Abort if no parameter or systems selected.
  if(counts == null) return (
    <div className={props.className}>
      Please select a system and a parameter.
    </div>
  );    


  // console.log(monthElements);

  let bars = dayElements;
  let barFormatter = (d: Date) => format(d, "dd MMM yyyy");
  let labels = monthElements;
  let labelFormatter = (d: Date) => format(d, "MMM yyyy");
  if(zoom < 2) {
    bars = monthElements;
    barFormatter = (d: Date) => format(d, "MMM yyyy");
    labels = yearElements;
    labelFormatter = (d: Date) => d.getFullYear().toString();
  } 
  if( zoom <= 0.2) {
    bars = yearElements;
    barFormatter = (d: Date) => d.getFullYear().toString();
    labels = decadeElements;
    labelFormatter = (d: Date) => Math.floor(d.getFullYear()/10)+"x";
  }

  return (
    <div className={props.className}>
      <Underpane>
      </Underpane>
      <Grid 
        bars={bars} 
        barFormatter={barFormatter} 
        labels={labels} 
        labelFormatter={labelFormatter} 
        zoom={zoom}/>
      <Actions>
        <Label color="black">Zoom level: {zoom}</Label>
        <Button size="small" onClick={handleZoomIn}><Icon name="zoom-plus"/> Zoom in</Button>
        <Button size="small" onClick={handleZoomOut}><Icon name="zoom-minus"/> Zoom out</Button>
      </Actions>
    </div>
  )
}

const Underpane = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: black;
  border-radius: 4px;
  opacity: 0.5;  
`

const Actions = styled.div`
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`

const ResultGrid = styled(ResultGridBase)`
  position: absolute;
  z-index: 100;
  bottom: 60px;
  left: 32px;
  right: 64px;
  height: 150px;
  color: white;
  display: flex;
  flex-direction: column;
`

export { ResultGrid }
