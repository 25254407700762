/**
 * Simple Rectangle class. Offers several functions for rectangle manipulation,
 * all of which are chainable.
 */
class Rect {
  /**
   * Create a new Rect instance.
   * @param left Left (x) coordinate.
   * @param top Top (y) coordinate.
   * @param width Width of rectangle.
   * @param height Height of rectangle.
   */
  constructor(public left: number, public top: number, public width: number, public height: number) { }

  /**
   * Returns a new rectangle, with `amount` of padding added on all sides.
   * @param amount Units of padding to add.
   * @returns Padded rectangle for chaining.
   */
  pad = (amount: number): Rect => {
    return new Rect(this.left - amount, this.top - amount, this.width + 2 * amount, this.height + 2 * amount); 
  }

  /**
   * Returns true if this rectangle intersects with rectangle `r`.
   * @param Rectangle to check against.
   * @returns true or false
   */
  intersect = (r: Rect): boolean => {
    return (this.left <= r.left + r.width &&
            r.left <= this.left + this.width &&
            this.top <= r.top + r.height &&
            r.top <= this.top + this.height)
  }

  /**
   * Returns a new rectangle that is the union of this rectangle and rectangle
   * `r`.
   * @param r Rectangle to union this rectangle with
   * @returns New, unioned rectangle for chaining
   */
  union = (r: Rect): Rect => {
    const minX = Math.min(this.left, r.left);
    const minY = Math.min(this.top, r.top);
    const maxX = Math.max(this.left + this.width, r.left + r.width);
    const maxY = Math.max(this.top + this.height, r.top + r.height);
    return new Rect(minX, minY, maxX - minX, maxY - minY);
  }  

  /**
   * Returns a new rectangle with a width that is at least equal to `amount`.
   * Narrow rectangles are extended on both sides, while the center point
   * of the rectangle does not move.
   * @param amount Desired minimum width
   * @returns New rectangle for chaining
   */
  minWidth = (amount: number): Rect => {
    let width = this.width;
    let left = this.left;
    if(width < amount) {
      const middle = left + width / 2;
      left = middle - amount / 2;
      width = amount;
    }    
    return new Rect(left, this.top, width, this.height);
  }

  /**
   * Returns a new rectangle with a height that is at least equal to `amount`.
   * Low rectangles are extended both above and below, while the center point
   * of the rectangle does not move.
   * @param amount Desired minimum height
   * @returns New rectangle for chaining
   */  
  minHeight = (amount: number): Rect => {
    let height = this.height;
    let top = this.top;
    if(height < amount) {
      const middle = top + height / 2;
      top = middle - amount / 2;
      height = amount;
    }    
    return new Rect(this.left, top, this.width, height);
  }  
  
  /**
   * Rectangle store only width. This method returns the right (x2) coordinate.
   */
  getRight = () => {
    return this.left + this.width;
  }

  /**
   * Rectangle store only height. This method returns the bottom (y2) coordinate.
   */  
  getBottom = () => {
    return this.top + this.height;
  }
}

export { Rect }
