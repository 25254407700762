import { Feature, FeatureCollection } from 'geojson';
import { TVisualizationStrategy } from "./TVisualizationStrategy";

import * as turf from '@turf/helpers';
import buffer from '@turf/buffer';
import booleanIntersects from '@turf/boolean-intersects';
import bufferedInterpolate from './bufferedInterpolate';
import union from '@turf/union';
import squareGrid from "@turf/square-grid";
import hexGrid from "@turf/hex-grid";
import triangleGrid from "@turf/triangle-grid";
import { bufferedBbox } from './bufferedBbox';
import { Measurement } from '../../api/models';

const idwStrategy: TVisualizationStrategy = (measurements: Measurement[]): FeatureCollection => {
  const DISTANCE = 1; // km
  console.log(measurements.length, "measurements");

  // * Avoiding very large areas resulting from distant systems *
  // This strategy should be applied once per system, producing a set of polygons
  // connected edgewise, but each system should have its own set of polygons.
  // The different sets should then be added to a single FeatureCollection.
  //
  // For a single system, this strategy should be applied.
  //
  // 1. The hull of the system should be calculated for later clipping.
  //
  // 2. Calculate a bounding box for all the stations, adding a certain 
  //    percentage to this bounding box to get results around the exterior
  //    stations, too.
  //
  // 3. Create a grid of rectangles that covers the bounding box. There
  //    should be 100 rectangles to a side, with the longest size having
  //    exactly 100 rectangles, while the shortest side has fewer 
  //    proportionally.
  //
  // 4. For each rectangle perform IDW to determine its value.

  let timeStart, timeEnd;

  // Create a point collection from the results.
  timeStart = Date.now();
  const pointCollection = turf.featureCollection( measurements
    .map(m => turf.point([ m.x, m.y ], { v: m.v })));  
  timeEnd = Date.now();
  console.log("Make points", timeEnd-timeStart, "ms");

  // Create a circle around every point, 10km in radius.
  timeStart = Date.now();
  const circles = pointCollection.features.map(p => buffer(p, DISTANCE * 20, { steps: 2, units: 'kilometers' }));
  timeEnd = Date.now();
  console.log("Make circles", timeEnd-timeStart, "ms");

  // Turn all the circles into a single multipolygon.
  timeStart = Date.now();
  let bigpoly = circles[0];
  for(let i = 1; i < circles.length; i++) {
    (bigpoly as any) = union(bigpoly, circles[i]);
  }  
  timeEnd = Date.now();
  console.log("Make multipolygon", timeEnd-timeStart, "ms");

  timeStart = Date.now();
  const box = bufferedBbox(pointCollection, 0.3);
  timeEnd = Date.now();
  console.log("Make buffered bbox", timeEnd-timeStart, "ms");

  timeStart = Date.now();
  const grid = hexGrid(box, DISTANCE, { units: 'kilometers'});
  timeEnd = Date.now();
  console.log("Make grid", timeEnd-timeStart, "ms");

  timeStart = Date.now();
  const sparseGrid = turf.featureCollection(grid.features.filter((f:Feature) => booleanIntersects(f, bigpoly)));
  timeEnd = Date.now();
  console.log(`${sparseGrid.features.length} of ${grid.features.length} squares`);
  console.log("Filter grid", timeEnd-timeStart, "ms");
  
  timeStart = Date.now();
  const squares = bufferedInterpolate(pointCollection, sparseGrid, DISTANCE, { property: 'v', units: 'kilometers', weight: 10 }) as any;
  timeEnd = Date.now();
  console.log("Interpolation", timeEnd-timeStart, "ms");

  return squares;
}

export { idwStrategy }
