import { BBox } from "@turf/helpers";
import bbox from '@turf/bbox';

const bufferedBbox = (geojson: any, factor: number): BBox => {
  // Find bounding box of all features:
  let [minLng, minLat, maxLng, maxLat] = bbox(geojson);

  // Get bounding box dimensions:
  let width = maxLng - minLng;
  let height = maxLat - minLat;

  // Add a percentage to this bounding box.
  minLng -= width * factor;
  maxLng += width * factor;
  minLat -= height * factor;
  maxLat += height * factor;

  // Returned buffered bounding box:
  return [minLng, minLat, maxLng, maxLat];
}

export { bufferedBbox } 
