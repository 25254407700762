import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Dropdown, Icon, Input } from '@independent-software/typeui/controls';

import { FilterContext } from '../../../contexts/FilterContext';
import { Parameter } from '../../../api/models';
import { Panel } from './Panel';
import { CurrentParameterCard } from './CurrentParameterCard';
import { Counter, Loader } from '../../../controls';
import { DrawerHeader } from '../../../controls/Drawer/DrawerHeader';
import { DrawerOptions } from '../../../controls/Drawer/DrawerOptions';

interface IProps {
  /** @ignore */
  className?: string;
  open?: boolean;
}

// Define different list orderings.
interface IOrder {
  name: string;
  sort: (a: Parameter, b: Parameter) => number;
}

const ORDERS: IOrder[] = [
  { name: "Order by name (A-Z)", sort: (a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 }, 
  { name: "Order by name (Z-A)", sort: (a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1 }
];

const ParametersTrayBase = (props: IProps) => {
  const filter = React.useContext(FilterContext);
  const [trayIndex, setTrayIndex] = React.useState(0);
  const [order, setOrder] = React.useState<IOrder>(ORDERS[0]);
  const [q, setQ] = React.useState("");

  const handleSelectParameter = (parameter: Parameter) => {
    filter.setParameter(parameter);
  }

  const handleClearParameter = () => {
    filter.setParameter(null);
  }

  const handleChangeQ = (value: string) => setQ(value);

  const handleChangeOrder = (value: IOrder) => setOrder(value);

  const getTitle = () => {
    if(filter.parameters == null || filter.parametercategories == null) return "Parameters";
    if(filter.parameter == null) {
      return <>Parameters <Counter size="mini">{filter.parameters.length}</Counter></>
    } else {
      return <CurrentParameterCard parameter={filter.parameter} onRemove={handleClearParameter}/>
    }    
  }

  const filteredParameters = filter.parameters ? filter.parameters
    .filter(parameter => parameter.name.toLowerCase().includes((q ?? "").toLowerCase()))
    .sort(order.sort) : null;

  if(!props.open) return null;

  return (
    <div className={props.className}>
      <DrawerHeader title={getTitle()} justify/>
      {(filter.parameters == null || filter.parametercategories == null) && <Loader/>}
      {filter.parameters != null && filter.parametercategories != null && <>
        <DrawerOptions>
          <Input fluid clearable type="text" placeholder="Search" value={q} onChange={handleChangeQ}>
            <Icon name="search"/>
          </Input>
          <Dropdown fluid data={ORDERS} value={order} label={(item: IOrder) => item.name} placeholder="Order" onChange={handleChangeOrder}>
            <Dropdown.Column>{(item: IOrder) => item.name}</Dropdown.Column>
          </Dropdown>
        </DrawerOptions>
        <Body>
          {filter.parametercategories.map((cat, idx) => {
            const params = filteredParameters
              .filter(p => p.parametercategories.map(c => c.id).includes(cat.id))
            if(params.length == 0) return null;
            return <Panel key={idx} title={cat.name} count={params.filter(p => p.data).length} open={trayIndex == idx} onToggle={() => setTrayIndex(idx)}>
              <table>
                <tbody>
                  {params.filter(p => p.data).map(p => 
                    <tr key={p.id} onClick={() => handleSelectParameter(p)}>
                      <td><span title={p.name}>{p.name}</span></td>
                      <td>{p.unit}</td>
                    </tr>
                  )}
                  {params.filter(p => !p.data).map(p => 
                    <tr key={p.id} className="disabled" onClick={() => handleSelectParameter(p)}>
                      <td><span title={p.name}>{p.name}</span></td>
                      <td>{p.unit}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Panel>
          })}
        </Body>
      </>}
    </div>
  );
}

const ParametersTray = styled(ParametersTrayBase)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  table {
    width: 100%;
    margin-bottom: 8px;
    table-layout: fixed;
    user-select: none;
    tr {
      background-color: #fff;
      cursor: pointer;
    }
    tr.disabled {
      color: #aaa;
    }
    tr:not(:last-child) {
      border-bottom: solid 1px #eee;
    }
    tr:hover {
      background-color: #dDe2e4;
    }
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 42px;
      vertical-align: middle;
    }
    td:first-child {
      padding-left: 24px;
      width: 75%;
      padding-right: 10px;
    }
    td:last-child {
      padding-right: 16px;
      width: 25%;
      font-size: 12px;
      color: #888;
      text-align: right;
    }
  }
`

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export { ParametersTray }
