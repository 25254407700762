import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
}

const LogoBase = (props: IProps) => 
  <svg className={props.className}>
    <use xlinkHref={`sprites.svg#aqua-logo`}></use>
  </svg>

const Logo = styled(LogoBase)`
  position: relative;
  width: 100%;
  height: 80px;
`

export { Logo }
