import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { System } from '../api/models';
import { keyframes } from 'styled-components';
import { Label, LabelStyled } from '@independent-software/typeui/controls';

const DASH_LENGTH = 8;
const BORDER_COLOR = "#fff";
const BORDER_WIDTH = 2;
const ANIM_DELAY = 1;

interface IProps {
  /** @ignore */
  className?: string;
  /** 
   * Collection of systems to show (there may be more than one due to overlap
   * of system boxes). 
   */
  systems: System[];
  /** Box width in pixels */
  width: number;
  /** Box height in pixels */
  height: number;
  /** 
   * Current map zoom level. This is used to reduce grayscale effect according
   * to zoom level.
   */
  zoom: number;
}

const SystemMarkerBase = (props: IProps) => 
  <div className={props.className}>
    <Rect/>
    <Systems>
      {props.systems.length > 1 && <Pill>{props.systems.length}</Pill>}
      {props.systems.map(s => <SystemName key={s.id}>{s.name}</SystemName>)}
    </Systems>
  </div>

const borderDance = keyframes` 
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left ${DASH_LENGTH}px top, right ${DASH_LENGTH}px bottom, left bottom ${DASH_LENGTH}px, right top ${DASH_LENGTH}px;
  }
`

const Rect = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background-image: linear-gradient(90deg, ${BORDER_COLOR} 50%, transparent 50%), 
                    linear-gradient(90deg, ${BORDER_COLOR} 50%, transparent 50%), 
                    linear-gradient( 0deg, ${BORDER_COLOR} 50%, transparent 50%), 
                    linear-gradient( 0deg, ${BORDER_COLOR} 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: ${DASH_LENGTH}px ${BORDER_WIDTH}px, ${DASH_LENGTH}px ${BORDER_WIDTH}px, ${BORDER_WIDTH}px ${DASH_LENGTH}px, ${BORDER_WIDTH}px ${DASH_LENGTH}px;
  background-position: left top, right bottom, left bottom, right top;
  animation: ${borderDance} ${ANIM_DELAY}s infinite linear;
`

const Pill = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #fff;
  padding: 0px 4px 0px 4px;
  background: black;
  font-weight: bold;
`

const Systems = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + 3px);  
  width: 100%;
  height: 20px;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0 4px 0 4px;
  &:hover {
    height: auto;
    width: auto;
    min-width: 100%;
    ${Pill} { display: none; }
  }
  background: rgb(0,0,0,0.5);
`

const SystemName = styled.div`
  position: relative;
  width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: #F6A44E;
  font-size: 14px;
  font-weight: bold;
  text-shadow:  1px  0px rgb(0,0,0,0.7),
               -1px  0px rgb(0,0,0,0.7),
                0px -1px rgb(0,0,0,0.7),
                0px  1px rgb(0,0,0,0.7);  
`

const SystemMarker = styled(SystemMarkerBase)`
  position: relative;
  width: ${p => p.width}px;
  height: ${p => p.height}px;

  ${Rect} {
    backdrop-filter: grayscale(${p => 100 - p.zoom * 10}%);
  }
`

export { SystemMarker }
