import * as React from 'react';

import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { ITableColumnProps } from './TableColumn';

interface IProps {
  /** Array of TableColumns */
  children?: React.ReactElement<ITableColumnProps> | React.ReactElement<ITableColumnProps>[];
  /** Table rows */
  data: any[];
  /** Fired when a row is clicked. */
  onClick?: (item: any) => void;
  /** Fired when a row is hovered. */
  onHover?: (item: any) => void;
  /** Is there a Check column in the header? */
  onCheck?: (index: number) => void;
  /** Is there an Add Column option in the header? */
  hasAddColumn?: boolean;
  /** Is there a Filter button in the header? */
  hasFilter?: boolean;
}

const TableBody = (props: IProps) => {
  // Prevent click on checkbox from reaching row.
  const captureClick = (e?: React.MouseEvent) => {
    e.stopPropagation();
  }
  
  return (
    <tbody>
      {/* For each row... */}
      {props.data.map((row, index) => 
        <tr key={index} className={row.selected ? "selected" : null} onMouseEnter={props.onHover ? () => props.onHover(row): null} onClick={props.onClick ? () => props.onClick(row) : null} style={{cursor: props.onClick ? 'pointer' : 'auto'}}>
          {!!props.onCheck === true && <td>
            <div onClick={captureClick}>
              <Checkbox type="check" checked={row.checked === true} onChange={() => props.onCheck(index)}/>
            </div>
          </td>}
          {/* Execute TableColumn's rendering function for each cell. */}
          {React.Children.map(props.children, (child, index) => 
            <td key={index} style={{textAlign: child.props.align === 'right' ? 'right' : 'left'}}>{child.props.children(row)}</td>
          )}
          {props.hasAddColumn === true && <td></td>}
          {props.hasFilter === true && <td></td>}
        </tr>
      )}
    </tbody>
  );
}

export { TableBody }
