import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { DrawerHeader } from '../../../controls/Drawer/DrawerHeader';
import { Button } from '@independent-software/typeui/controls';

interface IProps {
  /** @ignore */
  className?: string;
  open?: boolean;
}

const UserTrayBase = (props: IProps) => {
  if(!props.open) return null;

  const handleSignout = () => {
    /* axios.get(`${App.config.apiURL}signout?access_token=${auth.access_token}`)
    .then(_ => {
      navigate("/");
      auth.signout();
    })
    .catch(error => {
      ToastService.toast("Signout failed.");
    }); */    
  }    

  const handleAccount = () => {
    //navigate("/account");
  }

  return (
    <div className={props.className}>
      <DrawerHeader title="Jonathan Seagull"/>
      <Body>
        jonathan@seagull.org
        <div>
          <Button negative onClick={handleSignout}>Sign out</Button>
          <Button onClick={handleAccount}>Manage account</Button>
        </div>
      </Body>
    </div>
  );
}

const UserTray = styled(UserTrayBase)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Body = styled.div`
  margin: 16px;
`


export { UserTray }
