import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { ISkin, DefaultSkin } from '@independent-software/mapbox-ext/types/Skin';

import { StaticMap } from '../../../controls';
import { IMapstyle } from './StylePicker';

const HINT_GAP = 8;

interface IProps {
  /** @ignore */
  className?: string;
  /** Box size in pixels. */
  size: number;  
  /** Optional skin to apply. */
  skin?: ISkin;  
  /** Map style to draw. */
  style: IMapstyle;
  /** Fired when sample is clicked. */
  onClick: () => void;
}

const StyleSampleBase = (props: IProps) => {
  return (
    <div className={props.className} onClick={props.onClick}>
      <ImageContainer>
        <StaticMap 
          location={{latitude: 0, longitude: 0, zoom: 1}} 
          style={props.style.key}
          width={512}
          height={512}
        />
      </ImageContainer>
      <Hint>{props.style.name}</Hint>
    </div>
  )
}

const Hint = styled.div`
`

const ImageContainer = styled.div`
  
`

const StyleSample = styled(StyleSampleBase).attrs(p => ({
  skin: p.skin ?? DefaultSkin
}))`
  position: relative;
  width: ${p => p.size}px;
  height: ${p => p.size}px;

  ${ImageContainer} {
    border: solid 2px ${p => p.skin.border};
    border-radius: ${p => p.skin.radius}px;
    background: ${p => p.skin.fill};
    cursor: pointer;  
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 1px 1px 2px rgb(0,0,0,0.5);
    width: ${p => p.size}px;
    height: ${p => p.size}px;
  }

  ${Hint} {
    position: absolute;
    top: ${p => p.size + HINT_GAP}px;
    right: 0;
    color: #333;
    background: #DDDDDD;
    border: solid 2px #333333;
    border-radius: ${p => p.skin.radius}px;
    padding: 2px 8px 2px 8px;
    display: none;
    white-space: nowrap;
  }

  &:hover {
    ${Hint} {
      display: block;
    }
  }
`

export { StyleSample }
