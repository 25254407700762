import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const DrawerOptionsBase = (props: IProps) => {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  )
}

const DrawerOptions = styled(DrawerOptionsBase)` 
  height: 100px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  border-bottom: solid 1px #eee;
  background: #EDF2F4;
`

export { DrawerOptions }
