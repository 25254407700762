import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** Is corresponding column currently sorted? */
  active?: boolean;
  /** If set, sort button is reversed (Z-A). */
  reverse?: boolean;
}

const SortButtonBase = (props: IProps) => 
  <div className={props.className}>
    <svg viewBox="0 0 100 100">
      <use xlinkHref={"spritemap.svg#caret-down"}></use>
    </svg>
  </div>

/**
 * The SortButton displays a caret that rotates and lights up depending on
 * its active/reverse props.
 */
const SortButton = styled(SortButtonBase)`
  // Position
  position:    absolute;
  display:     flex; // Needed for SVG to be centered in div.
  top:         18px;
  left:        16px;
  width:       12px;
  height:      12px;

  // Appearance
  transition: opacity ease-in-out 150ms,
              transform ease-in-out 150ms;
  transform: rotate(${p => p.reverse ? 180 : 0}deg);

  svg {
    transition: fill ease-in-out 150ms;
    fill: ${p => p.active? "#fff": "#888"};
  }
`

export { SortButton }
