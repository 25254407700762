import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { keyframes } from 'styled-components';

//
// Shows a small loading component, consisting of three dots moving in a loop.
// 

// Animation duration (in ms)
const DURATION = 600;
// Default animation size
const DEFAULT_SIZE = 20;
// Default color of dots
const DEFAULT_COLOR = '#333';

interface IProps {
  /** @ignore */
  className?: string;
  /** Size of animation (in pixels). Defaults to `20`. */
  size?: number;
  /** Color of dots. Defaults to `#333` */
  color?: string;
}

const MiniLoaderBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

const dot_appear = keyframes` 
  0%   { transform: scale(0); }
  100% { transform: scale(1); }
`

const dot_vanish = keyframes`
  0%   { transform: scale(1); }
  20%  { transform: scale(1); }
  100% { transform: scale(0); }
`

const dot_move = (size:number) => keyframes`
  0%   { transform: translate(0, 0); }  
  100% { transform: translate(${size*0.3}px, 0); }
`

const MiniLoader = styled(MiniLoaderBase).attrs(p => ({
  size: p.size ?? DEFAULT_SIZE,
  color: p.color ?? DEFAULT_COLOR
}))`
  display: inline-block;
  position: relative;
  width: ${p => p.size}px;
  height: ${p => p.size}px;

  div {
    position: absolute;
    top: ${p => p.size*0.4125}px;
    width: ${p => p.size*0.1625}px;
    height: ${p => p.size*0.1625}px;
    border-radius: 50%;
    background: ${p => p.color};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: ${p => p.size*0.1}px;
    animation: ${DURATION}ms infinite ${dot_appear};
  }
  div:nth-child(2) {
    left: ${p => p.size*0.1}px;
    animation: ${DURATION}ms infinite ${p => dot_move(p.size)};
  }
  div:nth-child(3) {
    left: ${p => p.size*0.4}px;
    animation: ${DURATION}ms infinite ${p => dot_move(p.size)};
  }
  div:nth-child(4) {
    left: ${p => p.size*0.7}px;
    animation: ${DURATION}ms infinite ${dot_vanish};
  }
`

export { MiniLoader }
