import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Message } from '@independent-software/typeui/controls';

import { FilterContext } from '../../contexts/FilterContext';
import { Table } from '../../controls/Table';
import { CampaignApi } from '../../api/services/CampaignApi';
import { Campaign } from '../../api/models';
import { Loader } from '../../controls';

interface IProps {
}

const CampaignsTable = (props: IProps) => {
  const filter = React.useContext(FilterContext);
  const [campaigns, setCampaigns] = React.useState<Campaign[]>(null);

  React.useEffect(() => {
    const controller = new AbortController();
    setCampaigns([]);
    CampaignApi.list(null, { systems: filter.systems}, controller.signal)
    .then(res => {
      setCampaigns(res.data);
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    });    
    return () => { if(controller) controller.abort(); }            
  }, [filter.systems]);

  if(filter.systems.length == 0) {
    return (<Spacer>
      <Message type='info'>Please select a <b>system</b> in order to show campaigns.</Message>
    </Spacer>);    
  }

  if(campaigns === null) {
    return <Loader/>
  }

  const getFilter = () => {
    return (<>
      <div style={{margin: "16px"}}>
        Filter goes here.
      </div>
    </>);
  }

  return (
    <>
      <Table data={campaigns} striped hover grid sort="value" filter={getFilter()}>
        <Table.Column name="Name" sort="name">{(item: Campaign) => item.name}</Table.Column>
        <Table.Column name="Date" sort="date">{(item: Campaign) => item.date}</Table.Column>
      </Table>
    </>
  );
}

const Spacer = styled.div`
  margin: 16px;
`

export { CampaignsTable }
