import * as React from 'react';
import styled, { css } from '@independent-software/typeui/styles/Theme';

import { SortButton } from './buttons/SortButton';
import { RemoveColumnButton } from './buttons/RemoveColumnButton';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Percentage width. */
  width: number;
  /** If set, header cell shows sorting arrows. */
  sortable?: boolean;
  /** Sort direction */
  reverse: boolean;
  /** If set, header cell has an active color. */
  active?: boolean;
  /** Fired when header cell is clicked. */
  onClick?: () => void;
  /** If present, a column deletion option is added. */
  onDeleteColumn?: () => void;  
}

const TableHeaderCellBase = (props: IProps) =>
  <th className={props.className} onClick={props.onClick}>
    <SortButton active={props.active} reverse={props.reverse}/>
    <Content>
      <Label>
        {props.children}
      </Label>
      {props.onDeleteColumn &&
        <ControlBox>
          <RemoveColumnButton onClick={props.onDeleteColumn}/>
        </ControlBox>
      }
    </Content>
  </th> 

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`

const Label = styled.div`
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow:    hidden;
`

const ControlBox = styled.div`
  transition: flex ease-in-out 150ms, 
              opacity ease-in-out 150ms,
              padding-left ease-in-out 150ms;
  flex-basis: auto;
  overflow: hidden;
  flex: 0;
  opacity: 0;
  padding-left: 0;
`

const TableHeaderCell = styled(TableHeaderCellBase)`
  width: ${p => p.width}%;
  transition:  background-color ease-in-out 150ms,
               padding-left ease-in-out 150ms;

  ${SortButton} {
    opacity: 0;  
  }               

  ${p => p.active && css`
    padding-left: 34px !important;
    ${SortButton} {
      opacity: 1;  
    }                   
  `}

  ${p => p.sortable && css`
    cursor: pointer;
    ${p.active && css`background-color: #444 !important;`}
    &:hover {
      background-color: #666;
      padding-left: 34px !important;
      ${SortButton} {
        opacity: 1 !important;  
      }            
      ${ControlBox} {
        flex: 0 1 auto;
        opacity: 1;
        padding-left: 8px;
      }
    }
  `}
`

export { TableHeaderCell }
