import * as React from 'react';
import { StyleReset, StyleBase, Theme } from '@independent-software/typeui/styles';
import { ThemeProvider } from 'styled-components';
import { IThemeInterface } from '@independent-software/typeui/styles/Theme';
import { ToastContainer } from '@independent-software/typeui/controls/Toast';
import { Filter } from './contexts/Filter';
import { Screen } from './environment/Screen';

const MyTheme: IThemeInterface = {
  ...Theme,
  radius: 4
}

const Main = () => {
  return (
    <>
      <StyleReset/>
      <StyleBase/>
      <ThemeProvider theme={MyTheme}>
        <ToastContainer maxToasts={5} horizontalAlign='left' verticalAlign='bottom' horizontalOffset={20} verticalOffset={65}/>
        <Filter>
          <Screen/>
        </Filter>
      </ThemeProvider>
    </>
  );
}

export { Main }
