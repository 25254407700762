import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { IBucket } from '../Area/Bucketer';

interface IProps {
  className?: string;
  bucket: IBucket;
  formatter: (d: Date) => string;
}

const LabelBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <div>
        {props.formatter(props.bucket.date)}
      </div>
    </div>
  );
}

const Label = styled(LabelBase)`
  position: relative;
  background: transparent;
  grid-column-start: ${p => (p.bucket.start < 0 ? 0 : p.bucket.start) + 1};
  grid-column-end: span ${p => p.bucket.start < 0 ? p.bucket.length + p.bucket.start : p.bucket.length};
  grid-row-start: 2;
  display: flex;
  justify-content: center;
  & > div {
    flex: 1;
    background: steelblue;
    overflow-x: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    user-select: none;    
    margin-left: 1px;
    margin-right: 1px;    
    border-radius: 8px;
    border: solid 1px darkblue;
    box-sizing: border-box;
    color: white;
    white-space: nowrap;
  }
`

export { Label }