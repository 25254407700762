import { compareAsc, parse } from "date-fns";
import { Url } from "../../helpers/Url";
import { Api } from "../Api";
import { Campaign, ListResponse, Measurement, Parameter, Result, ResultCount, System } from "../models";

class ResultApi {

  /**
   * Retrieve list of parameters.
   */
  static list(access_token: string, options?: { parameter?: Parameter, systems?: System[] }, signal?: AbortSignal): Promise<ListResponse<Result>> {
    options = options || {};
    
    const url = new Url("results");
    if(options.systems) {
      url.append('system[]', `in-${options.systems.map(s => s.id).join(',')}`);
    }
    if(options.parameter) {
      url.append('parameter[]', `eq-${options.parameter.id}`)
    }

    return Api.Get<ListResponse<Result>>(url.toString(), access_token, signal);
  }

  /**
   * Retrieve list of results in a campaign, for a parameter.
   */
  static map(access_token: string, campaign: Campaign, parameter: Parameter, signal?: AbortSignal): Promise<Measurement[]> {
    let url = `results/map/${campaign.id}/${parameter.id}`;
    return Api.Get<Measurement[]>(url, access_token, signal);
  }

  /**
   * Retrieve a list of result counts by date (ordered by date asc).
   */
  static async getCounts(access_token: string, systems: System[], parameter: Parameter, signal?: AbortSignal): Promise<ResultCount[]> {
    const url = new Url("results-count");
    url.append("systems", systems.map(s => s.id));
    url.append("parameter", parameter.id);
    let res = await Api.Get<ResultCount[]>(url.toString(), access_token, signal);
    res = res.map((entry: ResultCount) => {
      const date = parse((entry.date as unknown) as string, 'yyyy-MM-dd', new Date());
      return { ...entry, date: date }
    }).sort((a, b) => compareAsc(a.date, b.date))
    return res;
  }  

}

export { ResultApi }
