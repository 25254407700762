class Url {
  private params: string[] = [];

  constructor(private host: string) { }

  public append(key: string, value: number | string | number[] | string[]) {
    if(Array.isArray(value)) {
      value.forEach(v => {
        this.params.push(`${key}[]=${v}`);
      });
    } else {
      this.params.push(`${key}=${value}`);
    }
  }

  public toString() {
    let str = this.host;
    if(this.params.length) {
      str += '?' + this.params.join('&');
    }
    return str;
  }
}

export { Url }