import type { CircleLayer } from 'react-map-gl';
import { CLUSTER_FILL_COLOR, CLUSTER_OPACITY_END, CLUSTER_OPACITY_START, CLUSTER_SIZE, CLUSTER_SIZE_STEP } from './ClusterConstants';

const ClustersCircleHoverLayer: CircleLayer = {
  id: "clusters-circles-hover",
  type: "circle",
  paint: {
    "circle-radius": [
      "+", 
        ["*", 
          [ "log10", [ "get", "point_count" ] ], 
          CLUSTER_SIZE_STEP], 
      CLUSTER_SIZE
    ],
    "circle-color": CLUSTER_FILL_COLOR,
  }
};

export { ClustersCircleHoverLayer }
