import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Dropdown, Icon, Input } from '@independent-software/typeui/controls';

import { Campaign } from '../../../api/models';
import { FilterContext } from '../../../contexts/FilterContext';
import { CampaignApi } from '../../../api/services/CampaignApi';
import { Counter, Loader } from '../../../controls';
import { CurrentCampaignCard } from './CurrentCampaignCard';
import { DrawerHeader } from '../../../controls/Drawer/DrawerHeader';
import { DrawerOptions } from '../../../controls/Drawer/DrawerOptions';
import { DrawerNodata } from '../../../controls/Drawer/DrawerNodata';

interface IProps {
  /** @ignore */
  className?: string;
  open?: boolean;
}

interface IOrder {
  name: string;
  sort: (a: Campaign, b: Campaign) => number;
}

const ORDERS: IOrder[] = [
  { name: "Order by name (A-Z)", sort: (a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 }, 
  { name: "Order by name (Z-A)", sort: (a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1 },
];

const CampaignsTrayBase = (props: IProps) => {
  const filter = React.useContext(FilterContext);
  const [order, setOrder] = React.useState<IOrder>(ORDERS[0]);
  const [q, setQ] = React.useState("");
  const [campaigns, setCampaigns] = React.useState<Campaign[]>(null);

  //
  // Retrieve Campaigns.
  // This happens when systems selection changes.
  // 
  React.useEffect(() => {
    const controller = new AbortController();
    CampaignApi.list(null, { systems: filter.systems }, controller.signal)
    .then(res => {
      setCampaigns(res.data);
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    });        
    return () => { if(controller) controller.abort(); }
  }, [filter.systems]);

  const handleSelectCampaign = (campaign: Campaign) => {
    filter.setCampaign(campaign);
  }

  const handleClearCampaign = () => {
    filter.setCampaign(null);
  }  

  const handleChangeQ = (value: string) => setQ(value);

  const handleChangeOrder = (value: IOrder) => setOrder(value);

  const getTitle = () => {
    if(campaigns == null) return "Campaigns";
    if(filter.campaign == null) {
      return <>Campaigns <Counter size="mini">{campaigns.length}</Counter></>
    } else {
      return <CurrentCampaignCard campaign={filter.campaign} onRemove={handleClearCampaign}/>
    }    
  }
  
  const filteredCampaigns = campaigns ? campaigns
    .filter(campaign => campaign.name.toLowerCase().includes((q ?? "").toLowerCase()))
    .sort(order.sort) : [];  

  if (!props.open) return null;

  return (
    <div className={props.className}>
      <DrawerHeader title={getTitle()} justify/>
      {campaigns == null && <Loader/>}
      {campaigns != null && <>
        <DrawerOptions>
          <Input fluid clearable type="text" placeholder="Search" value={q} onChange={handleChangeQ}>
            <Icon name="search"/>
          </Input>
          <Dropdown fluid data={ORDERS} value={order} label={(item: IOrder) => item.name} placeholder="Order" onChange={handleChangeOrder}>
            <Dropdown.Column>{(item: IOrder) => item.name}</Dropdown.Column>
          </Dropdown>
        </DrawerOptions>
        <Scroller>
          {filteredCampaigns.length == 0 && <DrawerNodata/>}
          <table>
            <tbody>
              {open && filteredCampaigns.map(campaign => 
                <tr key={campaign.id} onClick={() => handleSelectCampaign(campaign)}>
                  <td title={campaign.name}>{campaign.name}</td>
                  <td>{campaign.system.name}</td>
                </tr>
              )}
            </tbody>
          </table>
        </Scroller>
      </>}
    </div>
  );
}
  
const CampaignsTray = styled(CampaignsTrayBase)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  table {
    width: 100%;
    margin-bottom: 8px;
    table-layout: fixed;
    user-select: none;
    tr {
      background-color: #fff;
      cursor: pointer;
    }
    tr:not(:last-child) {
      border-bottom: solid 1px #eee;
    }
    tr:hover {
      background-color: #dDe2e4;
    }
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 42px;
      vertical-align: middle;
    }
    td:first-child {
      padding-left: 16px;
      width: 75%;
      padding-right: 10px;
    }
    td:last-child {
      padding-right: 16px;
      width: 25%;
      font-size: 12px;
      color: #888;
      text-align: right;
    }
  }  
`

const Scroller = styled.div`
  flex: 1;
  overflow-y: scroll;
`

export { CampaignsTray }
  