import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Panel } from '@independent-software/typeui/controls/Panel';

interface IProps {
  /** @ignore */
  className?: string;
  /** Contents of filter Panel. */
  filter: React.ReactNode;
}

const FilterColumnButtonBase = (props: IProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <FilterAnchor>
        <Panel open={open} onClose={() => setOpen(false)} width={300}>
          {props.filter}
        </Panel>
      </FilterAnchor>
      <div className={props.className} title="Filter" onClick={() => setOpen(!open)}>
        <svg viewBox="0 0 100 100">
          <use xlinkHref={"spritemap.svg#filter"}></use>
        </svg>
      </div>
    </>
  );
}

const FilterButton = styled(FilterColumnButtonBase)`
  display:     flex;
  box-sizing:  border-box;
  width:       17px;
  height:      17px;
  cursor: pointer;
  
  &:hover {
    svg {
      fill: #fff;
    }
  }
  svg {
    transition: fill ease-in-out 150ms;
    fill: #f0f0f0;
  }
`

const FilterAnchor = styled.div`
  // Position:
  position:    absolute;
  z-index:     100;
  right:       13px;
  bottom:      15px;

  // Dimensions:
  width:       20px;
  height:      0;

  // Make sure filter panel's content color is dark grey.
  color: #333;
`

export { FilterButton }

