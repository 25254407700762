import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';

import { ISkin, DefaultSkin } from '@independent-software/mapbox-ext/types/Skin';
import { StyleSample } from './StyleSample';

interface IMapstyle {
  key: string;
  name: string;
}

interface IProps {
  className?: string;
  /** Horizontal button position. A negative value is an offset from the right. */
  x: number;
  /** Vertical button position. A negative value is an offset from the bottom. */
  y: number;  
  /** Optional control width. Defaults to 50px. */
  size?: number;  
  /** Optional skin to apply. */
  skin?: ISkin;  
  /** Available map styles */
  styles: IMapstyle[];
  /** Active style */
  styleKey: string;
  /** Fired when map style is selected. */
  onSelect: (key: string) => void;
}

const StylePickerBase = (props: IProps) => {
  const wrapperRef = React.useRef(null as HTMLDivElement);
  const size = props.size ?? 50;
  const [open, setOpen] = React.useState(false);

  const handleSelect = (key: string) => {
    setOpen(!open);
    props.onSelect(key);
  }

  // Handle document-wide mousedown event by closing the picker if it's open.
  const handleClickOutside = (event: MouseEvent) => {
    const elem:Element = event.target as Element;
    if (wrapperRef.current && !wrapperRef.current.contains(elem)) {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    // Listen for document-wide mousedown event when Picker mounts.
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up document-wide mousedown event when Picker unmounts.
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [])

  return (
    <div className={props.className} ref={wrapperRef}>
      <StyleSample
        size={size}
        skin={props.skin}
        style={props.styles.find(s => s.key == props.styleKey)}
        onClick={() => handleSelect(props.styleKey)}
      />
      {open && <>
        {props.styles.filter(s => s.key != props.styleKey).map(s => 
          <StyleSample
            key={s.key}
            size={size}
            skin={props.skin}
            style={s}
            onClick={() => handleSelect(s.key)}
          />
        )}
      </>}
    </div>
  )
}

const StylePicker = styled(StylePickerBase).attrs(p => ({
  skin: p.skin ?? DefaultSkin
}))`
  position: absolute;
  ${p => p.x >= 0 && css`left:   ${ p.x}px;`}
  ${p => p.x < 0  && css`right:  ${-p.x}px;`}
  ${p => p.y >= 0 && css`top:    ${ p.y}px;`}
  ${p => p.y < 0  && css`bottom: ${-p.y}px;`}  
  z-index: 100;
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
`

export { StylePicker, IMapstyle }
