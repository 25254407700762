import * as React from 'react';

import { Checkbox } from '@independent-software/typeui/controls/Checkbox';

import { ITableColumnProps } from './TableColumn';
import { TableHeaderCell } from './TableHeaderCell';
import { AddColumnButton } from './buttons/AddColumnButton';
import { FilterButton } from './buttons/FilterButton';

interface IProps {
  /** @ignore */
  children?: React.ReactElement<ITableColumnProps> | React.ReactElement<ITableColumnProps>[];
  /** Current sort key. */
  sort?: string;
  /** Current sort direction. */
  reverse: boolean;
  /** Fired when a header cell is clicked. */
  onClick: (sort: string, reverse: boolean) => void;
  /** If present, a check column is added. */
  onCheck?: (index: number) => void;
  /** If present, a column deletion option is added. */
  onDeleteColumn?: (index: number) => void;  
  /** If present, an add column option is added. */
  onAddColumn?: () => void;  
  /** If present, a filter option is added. */
  filter?: React.ReactNode;
}

/** 
 * The TableHeader takes a a set of TableColumn children,
 * and uses these to format each header cell.
 */
const TableHeader = (props: IProps) => {
  // Calculate total weight of children, so that each child column can have
  // a percentage width.
  const totalWeight = React.Children.map(props.children, (child) => child.props.weight ?? 1).reduce((a,b) => a+b, 0);
  return (
    <thead>
      <tr>
        {props.onCheck && 
          <th style={{width: "48px"}}>
            <Checkbox type="check" onChange={() => props.onCheck(null)}/>
          </th>
        }
        {React.Children.map(props.children, (child, index) => 
          <TableHeaderCell 
            key={index}
            width={(child.props.weight ?? 1) * 100 / totalWeight}
            active={props.sort === child.props.sort}
            sortable={child.props.sort != null}
            reverse={props.sort === child.props.sort ? props.reverse : child.props.reverse}
            onClick={child.props.sort ? () => props.onClick(child.props.sort, child.props.reverse) : null}
            onDeleteColumn={props.onDeleteColumn ? () => props.onDeleteColumn(index) : null}
          >
            {child.props.name}
          </TableHeaderCell>
        )}
        {props.onAddColumn && 
          <th style={{width: "48px"}}>
            <AddColumnButton onClick={props.onAddColumn}/>
          </th>
         }
         {props.filter &&
          <th style={{width: "48px"}}>
            <FilterButton filter={props.filter}/>
          </th>         
         }
      </tr>
    </thead>
  );
}

export { TableHeader } 
