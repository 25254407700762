import { differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarYears, differenceInDays, differenceInMonths, differenceInYears, eachDayOfInterval, eachMonthOfInterval, eachYearOfInterval, endOfDecade, getDaysInMonth, getDaysInYear, max, min, startOfDecade, startOfMonth, startOfYear } from "date-fns";
import { ResultCount } from "../../../api/models";

type TBucketSize = 'year' | 'month' | 'day';

interface IOldBucket {
  date: Date;
  count: number;
}

interface IBucket {
  start: number;
  length: number;
  count: number;
  date: Date;
}

class Bucketer {
  public static getIntervalFunction = (bucketSize: TBucketSize): ((interval: Interval) => Date[]) => {
    switch(bucketSize) {
      case 'year': return eachYearOfInterval;
      case 'month': return eachMonthOfInterval;
      default: return eachDayOfInterval;
    }
  }

  public static getDifferenceFunction = (bucketSize: TBucketSize): ((dateLeft: Date, dateRight: Date) => number) => {
    switch(bucketSize) {
      case 'year': return differenceInCalendarYears;
      case 'month': return differenceInCalendarMonths;
      default: return differenceInCalendarDays;
    }
  }

  public static makeBuckets = (counts: ResultCount[], bucketSize: TBucketSize): IOldBucket[] => {
    // Create a list of buckets depending on the bucket size.
    const minDate: Date = min(counts.map(d => d.date));
    const maxDate: Date = max(counts.map(d => d.date));
    const intervalFunction = Bucketer.getIntervalFunction(bucketSize);
    const buckets: IOldBucket[] = intervalFunction({ start: minDate, end: maxDate}).map(d => { return {
      date: d,
      count: 0
    }});

    const differenceFunction = Bucketer.getDifferenceFunction(bucketSize);
    counts.forEach(c => {
      const bucketIndex = differenceFunction(c.date, minDate);
      buckets[bucketIndex].count += c.results;
    });
    
    return buckets;
  }

  private static createBuckets = (resultCounts: ResultCount[], startF: (date:Date) => Date, lengthF: (date: Date) => number): IBucket[] => {
    if(resultCounts.length == 0) return [];
    // Get min and max date.
    const minDate = resultCounts[0].date;
    const maxDate = resultCounts[resultCounts.length-1].date;

    const elements: IBucket[] = [];
    let element: IBucket = null;

    resultCounts.forEach(resultCount => {
      // Get date of start of month:
      const monthStart = startF(resultCount.date);
      // Get day index since start date:
      const index = differenceInCalendarDays(monthStart, minDate);
      if(element == null || element.start != index) {
        if(element != null) elements.push(element);
        element = { start: index, length: lengthF(resultCount.date), count: resultCount.results, date: monthStart };
      } else {
        element.count += resultCount.results;
      }
    });
    elements.push(element);
    return elements;
  }

  public static createDayBuckets = (resultCounts: ResultCount[]): IBucket[] => {
    return this.createBuckets(resultCounts, (x) => x, (x) => 1);
  }

  public static createMonthBuckets = (resultCounts: ResultCount[]): IBucket[] => {
    return this.createBuckets(resultCounts, startOfMonth, getDaysInMonth);
  }

  public static createYearBuckets =  (resultCounts: ResultCount[]): IBucket[] => {
    return this.createBuckets(resultCounts, startOfYear, getDaysInYear);
  }

  public static createDecadeBuckets = (resultCounts: ResultCount[]): IBucket[] => {
    return this.createBuckets(resultCounts, startOfDecade, (x) => differenceInCalendarDays(endOfDecade(x), startOfDecade(x)) + 1)
  }
}


export { Bucketer, IOldBucket, IBucket }
