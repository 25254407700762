import { Url } from "../../helpers/Url";
import { Api } from "../Api";
import { ListResponse, System } from "../models";

/**
 * List systems.
 */
class SystemApi {
  static list(access_token: string, signal?: AbortSignal): Promise<ListResponse<System>> {
    const url = new Url("systems");
    return Api.Get<ListResponse<System>>(url.toString(), access_token, signal);
  }
}

export { SystemApi }
