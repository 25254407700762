import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Button, Checkbox, Icon } from '@independent-software/typeui/controls';

import { System } from '../../../api/models';
import { SystemMap } from './SystemMap';
import { css } from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** `System` to place in card */
  system: System;
  /** Is system currently active? */
  active?: boolean;
  /** Fired when system is toggled. */
  onToggle: () => void;
}

const SystemCardBase = (props: IProps) => {
  //
  // Zooming to a system causes the `zoomToSystem` event to be dispatched.
  // Interested parties (the Map) listen to this and zoom to the system. 
  // The sidebar is also an interested party, as it closes when zooming occurs.
  // 
  const handleZoom = () => {
    const event = new CustomEvent("zoomToSystem", { detail: { system: props.system } });
    document.dispatchEvent(event);
  }

  return (
    <div className={props.className}>
      <Title>
        <Name>{props.system.name}</Name>
      </Title>
      <Stats>
        <Stat>{props.system.stations_count} {props.system.stations_count == 1 ? "station" : "stations" },</Stat>
        <Stat>{props.system.campaigns_count} {props.system.campaigns_count == 1 ? "campaign" : "campaigns"}</Stat>
      </Stats>
      {/* <SystemMap system={props.system}/> */}
      <Actions>
        <Checkbox type="toggle" checked={props.active} onChange={props.onToggle}/>
        {props.active && <Button size="tiny" onClick={handleZoom}><Icon name="search"/> Zoom to system</Button>}
      </Actions>
    </div>
  )
}

const Title = styled.div`
  user-select: none;
  padding: 10px 8px 0px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Name = styled.div`
  font-weight: 500;  
`

const Stats = styled.div`
  font-size: 12px;
  user-select: none;
  border-top: solid 1px #eee;
  padding: 4px 8px 4px 8px;
  color: #888;
`

const Stat = styled.span`
  margin-right: 8px;
`

const Actions = styled.div`
  padding: 8px 8px 8px 8px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SystemCard = styled(SystemCardBase)`
  border-radius: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  overflow: hidden;
  background: ${p => p.active ? "#EDF2F4" : "#f0f0f0"};
  box-shadow: 0px 2px 8px rgb(0,0,0,0.3);
  border: solid 1px rgb(0,0,0,0.3);
  ${p => p.active && css`
    border: solid 1px #2185D0;
  `}
`

export { SystemCard }
