import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';
import { ViewState } from 'react-map-gl';
import { DefaultSkin, ISkin } from '@independent-software/mapbox-ext/types/Skin';

import { Number } from '@independent-software/typeui/formatters/Number';
import { Table } from '@independent-software/typeui/controls';

interface IProps {
  /** @ignore */
  className?: string;
  /** Horizontal button position. A negative value is an offset from the right. */
  x: number;
  /** Vertical button position. A negative value is an offset from the bottom. */
  y: number;  
  /** Optional skin to apply. */
  skin?: ISkin;  
}

const DebugWindowBase = (props: IProps & ViewState) => {
  return (
    <div className={props.className}>
      <Table transparent striped>
        <tbody>
          <tr>
            <td>Lon</td><td><Number value={props.longitude} decimals={3}/></td>
          </tr>
          <tr>
            <td>Lat</td><td><Number value={props.latitude} decimals={3}/></td>
          </tr>
          <tr>
            <td>Zoom</td><td><Number value={props.zoom} decimals={2}/></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

const DebugWindow = styled(DebugWindowBase).attrs(p => ({
  skin: p.skin ?? DefaultSkin
}))`
  position: absolute;
  ${p => p.x >= 0 && css`left:   ${ p.x}px;`}
  ${p => p.x < 0  && css`right:  ${-p.x}px;`}
  ${p => p.y >= 0 && css`top:    ${ p.y}px;`}
  ${p => p.y < 0  && css`bottom: ${-p.y}px;`}  
  z-index: 100;
  width: 120px;
  border: solid 2px ${p => p.skin.border};
  border-radius: ${p => p.skin.radius}px;
  background: ${p => p.skin.fill};
  box-shadow: 1px 1px 2px rgb(0,0,0,0.5);  
  td:first-child {
    width: 50px;
  }
  td:last-child {
    text-align: right;
  }
`

export { DebugWindow }
