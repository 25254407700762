import * as React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';

import { Main } from './Main';

const ANIMATION_OPACITY_STEP = 0.5; // 0.01

interface IConfig {
  /** URL to back-end API. Should end with a slash. */
  apiURL:   string;
  /** Mapbox key. */
  mapboxKey:   string;
}

class App {
  static config: IConfig;

  //
  // Fade out, then remove the loading screen.
  //  
  private static removeLoader = () => {
    const loader = document.getElementById("apploader");
    let opacity = parseFloat(getComputedStyle(loader).opacity);
    opacity = Math.max(opacity - ANIMATION_OPACITY_STEP, 0);
    loader.style.opacity = opacity.toString();
    if(opacity > 0) {
      requestAnimationFrame(App.removeLoader);
    } else {
      loader.remove();
    }
  }

  static run = (node: React.ReactNode) => {
    const root = createRoot(document.getElementById('root'));
    axios.get<IConfig>("config.json")
    .then((res) => {
      App.config = res.data;
      root.render(node);
    })
    .catch(() => {
      root.render(
        "Error: Could not load config.json."
      );
    })
    // Remove loading screen.
    .finally(() => {
      App.removeLoader();
    })
  }
}

App.run(<Main/>);
  
export { App }
