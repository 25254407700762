import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Parameter } from '../../../api/models';
import { Icon, Label } from '@independent-software/typeui/controls';
import { Counter } from '../../../controls';

interface IProps {
  /** @ignore */
  className?: string;
  /** Parameter for card */
  parameter: Parameter;
  /** Fired when parameter must be removed. */
  onRemove: () => void;
}

const CurrentParameterCardBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Name title={props.parameter.name}>
        {props.parameter.name}
      </Name>
      <Unit>
        <Counter size="small">{props.parameter.unit}</Counter>
      </Unit>
      <Icon name="times" title="Deselect parameter" onClick={props.onRemove}/>
    </div>
  );
}

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`

const Unit = styled.div`
  font-weight: normal;
  font-size: 12px;
  text-align: right;
`

const CurrentParameterCard = styled(CurrentParameterCardBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  user-select: none;
  white-space: nowrap;
  gap: 8px;
`

export { CurrentParameterCard }
