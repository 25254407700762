import type { CircleLayer } from 'react-map-gl';
import { MARKER_STROKE_COLOR } from './ClusterConstants';

const MarkersStrokeLayer: CircleLayer = {
  id: "markers-stroke-hover",
  type: "circle",
  paint: {
    "circle-radius": 12,
    "circle-opacity": 0,
    "circle-color": "transparent",
    "circle-stroke-color": MARKER_STROKE_COLOR,
    "circle-stroke-opacity": 0.5,
    "circle-stroke-width": 6
  }
};

export { MarkersStrokeLayer }
