import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** Name of SVG element to show, e.g. `systems` */
  name: string;
}

const BigIconBase = (props: IProps) =>
  <svg>
    <use xlinkHref={`sprites.svg#${props.name}`}></use>
  </svg>

const BigIcon = styled(BigIconBase)`
  fill: #1E3663;
`

export { BigIcon }
