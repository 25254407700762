import * as React from 'react';
import { ViewState } from 'react-map-gl';

import { ZoomInButton, ZoomOutButton, CompassButton } from '@independent-software/mapbox-ext/controls/buttons';
import { ScaleControl } from '@independent-software/mapbox-ext/controls/ScaleControl';
import { Geocoder } from '@independent-software/mapbox-ext/controls/Geocoder';
import { DefaultSkin } from '@independent-software/mapbox-ext/types/Skin';

import { App } from '../App';
import { Mapstyles } from './Mapstyles';
import { FilterContext } from '../contexts/FilterContext';
import { ZoomInHint, ZoomOutHint, CompassHint } from './controls/hints';
import { StylePicker } from './controls/StylePicker/StylePicker';
import { DebugWindow } from './controls/DebugWindow/DebugWindow';

const CONTROLS_OFFSET_LEFT = 16;
const CONTROLS_OFFSET_RIGHT = 16;
const CONTROLS_OFFSET_TOP = 16;
const CONTROLS_OFFSET_BOTTOM = 32;
const CONTROLS_BUTTON_SPACING = 34;

const MapControls = (props: ViewState) => {
  const filter = React.useContext(FilterContext);

  return (<>
    <StylePicker onSelect={(key: string) => filter.setMapstyle(key)} styles={Mapstyles} styleKey={filter.mapstyle} skin={DefaultSkin} x={-CONTROLS_OFFSET_RIGHT - 48} y={-CONTROLS_OFFSET_TOP - 51}/>
    <ScaleControl {...props} x={-CONTROLS_OFFSET_RIGHT} y={-CONTROLS_OFFSET_BOTTOM}/>
    <ZoomOutButton {...props} attachedTop skin={DefaultSkin} x={-CONTROLS_OFFSET_RIGHT} y={-CONTROLS_OFFSET_BOTTOM - 1*CONTROLS_BUTTON_SPACING} hint={<ZoomOutHint/>}/>
    <ZoomInButton {...props} attachedTop attachedBottom skin={DefaultSkin} x={-CONTROLS_OFFSET_RIGHT} y={-CONTROLS_OFFSET_BOTTOM - 2*CONTROLS_BUTTON_SPACING} hint={<ZoomInHint/>}/>
    <CompassButton {...props} visualizePitch attachedBottom skin={DefaultSkin} x={-CONTROLS_OFFSET_RIGHT} y={-CONTROLS_OFFSET_BOTTOM - 3*CONTROLS_BUTTON_SPACING} hint={<CompassHint/>}/>
    <Geocoder access_token={App.config.mapboxKey} skin={DefaultSkin} x={-CONTROLS_OFFSET_RIGHT} y={CONTROLS_OFFSET_TOP}/>        
    <DebugWindow {...props} x={CONTROLS_OFFSET_LEFT} y={CONTROLS_OFFSET_TOP}/>
  </>);
}

export { MapControls }
