import * as React from 'react';
import { CanceledError } from 'axios';
import { Map as GLMap, MapboxMap, Source, Layer, ViewStateChangeEvent, MapLayerMouseEvent, MapboxGeoJSONFeature } from 'react-map-gl';
import * as turf from '@turf/helpers';

import { App } from '../App';
import { MapControls } from './MapControls';
import { Measurement, Station, System } from '../api/models';
import { ClustersCircleHoverLayer, ClustersCircleLayer, ClustersInnerStrokeHoverLayer, ClustersInnerStrokeLayer, ClustersOuterStrokeHoverLayer, ClustersOuterStrokeLayer, ClustersSymbolLayer, MarkersCircleLayer, MarkersStrokeHoverLayer, MarkersStrokeLayer } from './layers';
import { FeatureCollection } from 'geojson';
import { FilterContext } from '../contexts/FilterContext';
import {HeatmapLayer} from '@deck.gl/aggregation-layers/typed';
import { DeckGLOverlay } from './DeckGLOverlay';
import { VisualizationFillLayer } from './layers/VisualizationFillLayer';
import { idwStrategy } from './visualizationstrategies/idwStrategy';
import { VisualizationSymbolLayer } from './layers/VisualizationSymbolLayer';
import { DateSlider } from './controls/DateSlider/DateSlider';
import { StationApi } from '../api/services/StationApi';
import { ResultApi } from '../api/services/ResultApi';
import { SystemMarkers } from './SystemMarkers';
import { StationPanel } from './StationPanel';
import { CLUSTER_CUTOFF } from './layers/ClusterConstants';
import { Area } from './controls/Area/Area';
import { ResultGrid } from './controls/ResultGrid/ResultGrid';

interface IProps {
  // Location initially shown on map. Can be null.
  lat?: number;
  lng?: number;
  // Initial zoom level.
  zoom?: number;  
}

const strategy = idwStrategy;

const Map = (props: IProps) => {
  const map = React.useRef<MapboxMap>(null);
  const filter = React.useContext(FilterContext);
  const [stations, setStations] = React.useState<Station[]>([]);
  const [station, setStation] = React.useState<Station>(null);
  const [results, setResults] = React.useState<Measurement[]>([]);
  const [interactiveLayerIds, setInteractiveLayerIds] = React.useState([]);
  const [hoverStation, setHoverStation] = React.useState<Station>(null);
  const [hoverCluster, setHoverCluster] = React.useState<number>(null);
  const [features, setFeatures] = React.useState<FeatureCollection>(null);
  const [visualization, setVisualization] = React.useState(null);

  React.useEffect(() => {
    // Listen for document-wide zoomtoSystem event when Map mounts.
    document.addEventListener('zoomToSystem', handleZoomToSystem);
    return () => {
      // Clean up document-wide zoomToSystem event when Map unmounts.
      document.removeEventListener('zoomToSystem', handleZoomToSystem);
    }
  }, [])

  const handleZoomToSystem = (e: CustomEvent) => {
    const system: System = e.detail.system;
    console.log(system);
    map.current.fitBounds([[ system.minlng, system.minlat], [system.maxlng, system.maxlat]]);
  }

  React.useEffect(() => {
    const controller = new AbortController();
    StationApi.list(null, { systems: filter.systems }, controller.signal)
    .then(res => {
      setStations(res.data);
      const featureCollection = turf.featureCollection(
        (res.data as Station[])
          .filter(s => s.latitude != null && s.longitude != null)
          .map(s => { 
            const p = turf.point([s.longitude, s.latitude]);
            p.properties.id = s.id;
            return p;
          })
      );    
      setFeatures(featureCollection);  
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    });

    return () => { if(controller) controller.abort(); }
  }, [filter.systems]);

  React.useEffect(() => {
    setResults([]);
    if(filter.parameter == null) return;
    if(filter.systems.length == 0) return;
    if(filter.campaign == null) return;

    const controller = new AbortController();

    ResultApi.map(null, filter.campaign, filter.parameter, controller.signal)
    .then(res => {
      setResults(res);
      const timeStart = Date.now();
      const vis = strategy(res);
      const timeEnd = Date.now();
      console.log("Total visualization time:", timeEnd-timeStart, "ms");
      setVisualization(vis);
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    });    

    return () => { if(controller) controller.abort(); }
  }, [filter.parameter, filter.systems, filter.campaign]);

  // If lng/lat prop a valid location?
  const isLocationValid = (): boolean => {
    if(isNaN(parseFloat(props.lat as any)) || isNaN(parseFloat(props.lng as any))) {
       return false;
    }
    return true;
  }  

  const [viewState, setViewState] = React.useState({
    longitude: isLocationValid() ? props.lng : 0,
    latitude: isLocationValid() ? props.lat : 0,
    zoom: props.zoom ?? 1,
    bearing: 0,
    pitch: 0,
    padding: { top: 0, bottom: 0, left: 0, right: 0 }    
  });  

  const handleLoad = (e: mapboxgl.MapboxEvent) => {
    map.current = e.target;
    setInteractiveLayerIds([ 'clusters-circles', 'clusters-circles-hover', 'markers-circles' ]);
  }  

  const handleMove = (e: ViewStateChangeEvent) => {
    setViewState(e.viewState);
  }

  const handleMouseMove = (e: MapLayerMouseEvent) => {
    // If source isn't yet loaded, MapGL throws an error:
    if(!map.current || !map.current.loaded) return;

    // Turn hovering off:
    setHoverStation(null);
    setHoverCluster(null);
    if(e.features.length == 0) return;

    // Find associated IDs:
    const featureID = e.features[0].properties.id;
    const clusterID = e.features[0].properties.cluster_id;

    if(clusterID) { 
      setHoverCluster(clusterID);
      return;
    }

    if(featureID) {
      setHoverStation(stations.find(e => e.id === featureID));
    }
  }  

  const handleMouseLeave = (e: MapLayerMouseEvent) => {
    setHoverStation(null);
    setHoverCluster(null);
  }  

  const handleClickCluster = (feature: MapboxGeoJSONFeature) => {
    // Get the cluster ID from the feature:
    const clusterId = feature.properties.cluster_id;
    // Get the zoom level for the cluster, then zoom the cluster's
    // coordinates.
    (map.current.getSource("src") as any).getClusterExpansionZoom(
      clusterId,
      (err: any, zoom: number) => {
        if(err) return;
        map.current.easeTo({ center: (feature.geometry as any).coordinates, zoom });
      });
  }

  const handleClickMarker = (feature: MapboxGeoJSONFeature) => {
    const selectedStation = stations.find(s => s.id == feature.properties.id);
    setStation(selectedStation);

  }

  const handleClick = (e: MapLayerMouseEvent) => {
    // Check for clicks on clusters-circles layer:
    let features = map.current.queryRenderedFeatures(e.point, { layers: ['clusters-circles-hover']});
    if(features.length > 0) handleClickCluster(features[0]);
    // Check for clicks on markers-circles layer:
    features = map.current.queryRenderedFeatures(e.point, { layers: ['markers-circles']});
    if(features.length > 0) handleClickMarker(features[0]);
  }

  const layers = [
    /* new TextLayer({
      data: results,
      getPosition: (r: IValue) => [r.lng, r.lat, 250],
      getText: (r: IValue) => Math.round(r.v).toString(),
      getSize: 20
    }), */
    new HeatmapLayer({ 
      id: 'heatmap-layer',
      beforeId: "clusters-circles",
      data: results, 
      getPosition: (r: Measurement) => [r.x, r.y],
      getWeight: (r: Measurement) => r.v
    }),
    /* new ColumnLayer({
      id: 'column-layer',
      data: results,
      getPosition: (r: IValue) => [r.lng, r.lat],
      getFillColor: r => [128, 48, (r.v - 18) / 14 * 255, 255],
      getLineColor: [0, 0, 0],      
      getElevation: (r: IValue) => r.v - 15,
      elevationScale: 600
    }) */
  ];

  return (
    <GLMap
      {...viewState}
      mapboxAccessToken={App.config.mapboxKey}
      style={{width: '100%', height: '100%'}}
      logoPosition="bottom-left"  // Moves mapbox logo
      fadeDuration={0}            // Prevents fading in of cluster labels
      interactiveLayerIds={interactiveLayerIds}
      mapStyle={`mapbox://styles/${filter.mapstyle}`}
      minZoom={1}
      maxZoom={20}
      cursor={hoverCluster || hoverStation ? 'pointer' : ''}
      doubleClickZoom={false}
      //Events
      onLoad={handleLoad}
      onMove={handleMove}      
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <MapControls {...viewState}/>

      {/* <DateSlider/>
      <Area/> */}
      {filter.parameter && filter.systems.length > 0 && 
      <ResultGrid/>}

      {/* {stations.length > 0 && <DeckGLOverlay interleaved layers={layers}/>} */}

      <Source id="visualization" type="geojson" data={visualization}>
        <Layer {...VisualizationFillLayer}/>
      </Source>

      <Source id="src" type="geojson" data={features} cluster={true} clusterMaxZoom={CLUSTER_CUTOFF-1} clusterRadius={80}>
        <Layer {...ClustersCircleLayer} filter={['all', ['get', 'cluster'], ['!=', ['get', 'cluster_id'], hoverCluster ]]}/>
        <Layer {...ClustersCircleHoverLayer} filter={['all', ['get', 'cluster'], ['==', ['get', 'cluster_id'], hoverCluster ]]}/>
        <Layer {...ClustersOuterStrokeLayer} filter={['all', ['get', 'cluster'], ['!=', ['get', 'cluster_id'], hoverCluster ]]}/>
        <Layer {...ClustersInnerStrokeLayer} filter={['all', ['get', 'cluster'], ['!=', ['get', 'cluster_id'], hoverCluster ]]}/>
        <Layer {...ClustersOuterStrokeHoverLayer} filter={['all', ['get', 'cluster'], ['==', ['get', 'cluster_id'], hoverCluster ]]}/>
        <Layer {...ClustersInnerStrokeHoverLayer} filter={['all', ['get', 'cluster'], ['==', ['get', 'cluster_id'], hoverCluster ]]}/>
        <Layer {...ClustersSymbolLayer} filter={['get', 'cluster']}/>

        <Layer minzoom={CLUSTER_CUTOFF} {...MarkersCircleLayer}/>
        <Layer minzoom={CLUSTER_CUTOFF} {...MarkersStrokeLayer} filter={['!=', ['get', 'id'], hoverStation ? hoverStation.id : null ]}/>
        <Layer minzoom={CLUSTER_CUTOFF} {...MarkersStrokeHoverLayer} filter={['==', ['get', 'id'], hoverStation ? hoverStation.id : null ]}/>
      </Source>    

      <Source id="measurements"  type="geojson" data={turf.featureCollection(results
         .map(m => turf.point([ m.x, m.y ], { v: Math.round(m.v * 10)/10})))}>
        <Layer {...VisualizationSymbolLayer}/>
      </Source>      

      <SystemMarkers systems={filter.systems}/>

      {station && 
        <StationPanel station={station} onClose={() => setStation(null)}/>}
      
    </GLMap>
  );
}

export { Map }
