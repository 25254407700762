import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Mouse } from '../../../controls';

interface IProps {
  /** @ignore */
  className?: string;
}

const ZoomOutHintBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Mouse wheel/>
      Zoom out
    </div>
  );
}

const ZoomOutHint = styled(ZoomOutHintBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export { ZoomOutHint }
