import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Icon, IconStyled } from '@independent-software/typeui/controls';
import { css } from 'styled-components';

const TRANSITION_TIME = 300;
const TAB_RADIUS = 4;

type TSplitterState = 'full' | 'open' | 'closed';

interface IProps {
  /** @ignore */
  className?: string;
  /** Content for left-hand panel of splitter. */
  left: React.ReactNode;
  /** Content for right-hand panel of splitter. */
  right: React.ReactNode;
  /** Splitter state (full, open, closed) */
  state: TSplitterState;
  /** Fired on splitter state change. */
  onState: (state: TSplitterState) => void;
  /** Fired when splitter is clicked anywhere. */
  onClick: () => void;
}

const SplitterBase = (props: IProps) => {
  const wrapperRef = React.useRef(null as HTMLDivElement);

  // Handle document-wide mousedown event by closing the picker if it's open.
  const handleClickOutside = (event: MouseEvent) => {
    const elem:Element = event.target as Element;
    if (wrapperRef.current && wrapperRef.current.contains(elem)) {
      props.onClick();
    }
  }

  React.useEffect(() => {
    // Listen for document-wide mousedown event when Picker mounts.
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up document-wide mousedown event when Picker unmounts.
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [])

  const handleOpen = () => {
    if(props.state == 'closed') {
      props.onState('open');
    } else if(props.state == 'open') {
      props.onState('full');
    }
  }

  const handleClose = () => {
    if(props.state == 'full') {
      props.onState('open');
    } else if(props.state == 'open') {
      props.onState('closed');
    }    
  }

  return (
    <div className={props.className} ref={wrapperRef}>
      <PaneLeft>
        {props.left}
      </PaneLeft>
      <PaneRight>
        {props.right}
        <Tabs>
          <Tab onClick={handleOpen} style={{transform: "scaleX(-1)"}}>
            <Icon name="chevron"/>
          </Tab>
          <Tab onClick={handleClose}>
            <Icon name="chevron"/>
          </Tab>
        </Tabs>
      </PaneRight>
    </div>
  )
}

const Tabs = styled.div`
  position: absolute;
  z-index: 9999;
  width: 22px;
  top: calc(50% - 50px);
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: left ease-in-out ${TRANSITION_TIME}ms;
`

const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  background: #eee;
  border-radius: ${TAB_RADIUS}px;
  border: solid 1px #333;
  cursor: pointer;  
  transition: opacity ease-in-out ${TRANSITION_TIME}ms;  
  box-shadow: 1px 1px 3px rgb(0,0,0,.3);
  &:hover {
    background-color: #fff;
  }
`

const PaneLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: width ease-in-out ${TRANSITION_TIME}ms;
`

const PaneRight = styled.div`
  position: absolute;
  height: 100%;
  transition: left ease-in-out ${TRANSITION_TIME}ms,
              width ease-in-out ${TRANSITION_TIME}ms;
  background: #fff;
  border-left: solid 1px #666;  
`

const Splitter = styled(SplitterBase)`
  flex: 1;
  position: relative;
  ${Tabs} {
    ${p => p.state == 'closed' && css`left: -22px;`}
    ${p => p.state == 'open'   && css`left: -11px;`}
    ${p => p.state == 'full'   && css`left: -3px;`}
  }
  ${Tab}:first-child {
    opacity: ${p => p.state == 'full' ? 0 : 1};
  }
  ${Tab}:last-child {
    opacity: ${p => p.state == 'closed' ? 0 : 1};
  }  
  ${PaneLeft} {
    ${p => p.state == 'closed' && css`width: 100%;`}
    ${p => p.state == 'open'   && css`width: 50%;`}
    ${p => p.state == 'full'   && css`width: 50%;`}
  }
  ${PaneRight} {
    z-index: 100;
    ${p => p.state == 'closed' && css`left: 100%; width: 50%;`}
    ${p => p.state == 'open'   && css`left: 50%; width: 50%;`}
    ${p => p.state == 'full'   && css`left: 0%; width: 100%;`}
  }
`

export { Splitter, TSplitterState }
