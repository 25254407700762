import * as React from 'react';

interface ITableColumnProps {
  /** Column name, e.g. "Number of Users" */
  name: React.ReactNode;  
  /** Column weight. Defaults to `1`. */
  weight?: number;
  /** Optional sort key. If not specified, column is not sortable. */
  sort?: string;
  /** If true, default sorting is reverse sort. */
  reverse?: boolean;
  /** Cell content alignment. Defaults to `left`. */
  align?: 'left' | 'right';
  /** Function that takes a table row and returns cell content. */
  children: (item: any) => React.ReactNode;
}

/**
 * TableColumn species a column name, and how to render content for that
 * column. 
 * TableColumn itself doesn't render anything.
 */
const TableColumn = (_: ITableColumnProps): null => null;

export { TableColumn, ITableColumnProps }