import * as React from 'react';
import { FilterContext, IFilter } from './FilterContext';
import { Campaign, Parameter, Parametercategory, System } from '../api/models';
import { Mapstyles } from '../map/Mapstyles';
import { ParameterApi } from '../api/services/ParameterApi';

interface IProps {
  children?: React.ReactNode;
}

const Filter = (props: IProps) => {
  const [systems, setSystems] = React.useState<System[]>([]);
  const [parameter, setParameter] = React.useState<Parameter>(null);
  const [parameters, setParameters] = React.useState<Parameter[]>([]);
  const [parametercategories, setParametercategories] = React.useState<Parametercategory[]>([]);
  const [campaign, setCampaign] = React.useState<Campaign>(null);
  const [mapstyle, setMapstyle] = React.useState<string>(Mapstyles[0].key);

  const value: IFilter = {
    systems: systems,
    setSystems: setSystems,
    parameter: parameter,
    setParameter: setParameter,
    parameters: parameters,
    setParameters: setParameters,
    parametercategories: parametercategories,
    setParametercategories: setParametercategories,
    campaign: campaign,
    setCampaign: setCampaign,
    mapstyle: mapstyle,
    setMapstyle: setMapstyle
  }

  //
  // Load parameter categories. This happens only once.
  // 
  React.useEffect(() => {
    setParametercategories(null); // This is the loading state.
    ParameterApi.listCategories(null)
    .then(res => {
      setParametercategories(res.data);
    })
  }, []);

  // 
  // Load parameters. This happens when systems or campaign changes.
  //
  React.useEffect(() => {
    const controller = new AbortController();
    setParameters(null); // This is the loading state.
    ParameterApi.list(null, systems, campaign, controller.signal)
    .then(res => {
      setParameters(res);
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    });

    return () => { if(controller) controller.abort(); }   
  }, [systems, campaign]);

  return (
    <FilterContext.Provider value={value}>
      {props.children}
    </FilterContext.Provider>
  );
}

export { Filter }
