import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';

interface IProps { 
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** 
   * Fired when BarButton is clicked. BarButton will not be clickable if no
   * click handler is provided.
   */
  onClick?: () => void;
  /**
   * Is the BarButton currently active? It will have a background color that
   * reflects this.
   */
  active?: boolean;
  /** 
   * Count to display on top of BarButton. If not provided, no count is 
   * shown. 
   */
  count?: number;
  /**
   * An Annotation floats to the right of the BarButton, if provided.
   */
  annotation?: React.ReactNode;
  /** 
   * An optional text is printed under the BarButton. 
   */
  text?: string;
}

const BarButtonBase = (props: IProps) => 
  <div className={props.className} onClick={props.onClick}>
    <Content>
      <Pill>
        {props.children}
      </Pill>
    </Content>
    {props.count != null && <Counter>
      {props.count}
    </Counter>}
    {props.annotation && <Annotation>
      {props.annotation}
    </Annotation>}
    {props.text && <Text>
      {props.text}
    </Text>}
  </div>

const Text = styled.div`
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #333;
  line-height: 16px;
  padding: 2px 4px 4px 4px;
`

const Annotation = styled.div`
  position: absolute;
  z-index: -999;
  top: 30px;
  left: 100%;
`

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
`

const Pill = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  top: 9px;
  bottom: 0;
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
`

const BarButton = styled(BarButtonBase)`
  position: relative;
  width: 80px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  cursor: ${p => p.onClick ? "pointer" : "default"};
  ${Content} {
    svg {
      width: 100%;
      height: 100%;
      fill: #1E3663;
    }
  }

  ${p => p.onClick && css`
    &:hover {
      ${Content} {
        ${Pill} {
          background: #E0E4E6;
        }
      }
    }  
    ${p.active && css`
      ${Content} {
        ${Pill} {
          background: #ffffff !important;
        }
        svg {
          fill: #3BAEE2;
        } 
      }
    `}  
  `}
`

const Counter = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 10px;
  background: #E9A759;
  border-radius: 4px;
  white-space: nowrap;
  padding: 4px;
  font-size: 12px;
  color: #fff;
  box-shadow: 1px 1px 1px rgb(0,0,0,0.3);
`

export { BarButton }
