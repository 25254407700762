import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';

interface IProps {
  className?: string;
  /** Title. Can be JSX. */
  title: React.ReactNode;
  justify?: boolean;  
}

const DrawerHeaderBase = (props: IProps) => {
  return (
    <div className={props.className}>
      {props.title}
    </div>
  )
}

const DrawerHeader = styled(DrawerHeaderBase)` 
  height: 40px;
  min-height: 40px;
  box-sizing: border-box;
  border-bottom: solid 1px #ddd;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 500;
  user-select: none;
  background-color: #fff;
  ${p => p.justify && css`
    justify-content: space-between;
  `}
`

export { DrawerHeader }
