import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { IBucket } from '../Area/Bucketer';

interface IProps {
  className?: string;
  bucket: IBucket;
  maxCount: number;
  formatter: (d: Date) => string;
}

const BarBase = (props: IProps) => {
  return (
    <div className={props.className}  title={props.formatter(props.bucket.date) + ": " + props.bucket.count.toString()}>
      <div>
      </div>
    </div>
  );
}

const Bar = styled(BarBase)`
  position: relative;
  background: transparent;
  cursor: pointer;
  grid-column-start: ${p => (p.bucket.start < 0 ? 0 : p.bucket.start) + 1};
  grid-column-end: span ${p => p.bucket.start < 0 ? p.bucket.length + p.bucket.start : p.bucket.length};
  grid-row-start: 1;
  & > div {
    position: absolute;
    left: 0;
    bottom: 0;
    height: ${p => p.bucket.count == 0 ? 0 : Math.max(p.bucket.count / p.maxCount * 100, 5)}%;
    right: 0;
    min-width: 5px;
    background: gold;
    opacity: 0.65;
    border-top: solid 1px #333;
    border-right: solid 1px #333;
  }
  &:hover {
    & > div {
      opacity: 1;
    }
  }
`

export { Bar }
