import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Dropdown, Icon, Input, Label } from '@independent-software/typeui/controls';

import { FilterContext } from '../../../contexts/FilterContext';
import { SystemCard } from './SystemCard';
import { System } from '../../../api/models';
import { Counter, Loader } from '../../../controls';
import { SystemApi } from '../../../api/services/SystemApi';
import { DrawerHeader } from '../../../controls/Drawer/DrawerHeader';
import { DrawerOptions } from '../../../controls/Drawer/DrawerOptions';
import { DrawerNodata } from '../../../controls/Drawer/DrawerNodata';

interface IProps {
  /** @ignore */
  className?: string;
  open?: boolean;
}

// Define different list orderings.
interface IOrder {
  name: string;
  sort: (a: System, b: System) => number;
}

const ORDERS: IOrder[] = [
  { name: "Order by name (A-Z)", sort: (a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 }, 
  { name: "Order by name (Z-A)", sort: (a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1 },
  { name: "Order by stations (most first)", sort: (a, b) => b.stations_count - a.stations_count },
  { name: "Order by stations (least first)", sort: (a, b) => a.stations_count - b.stations_count },
  { name: "Order by campaigns (most first)", sort: (a, b) => b.campaigns_count - a.campaigns_count },
  { name: "Order by campaigns (least first)", sort: (a, b) => a.campaigns_count - b.campaigns_count }
];

const SystemsTrayBase = (props: IProps) => {
  const filter = React.useContext(FilterContext);
  const [order, setOrder] = React.useState<IOrder>(ORDERS[0]);
  const [q, setQ] = React.useState("");
  const [systems, setSystems] = React.useState<System[]>(null);

  React.useEffect(() => {
    const controller = new AbortController();
    SystemApi.list(null, controller.signal)
    .then(res => {
      setSystems(res.data);
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    });    
    return () => { if(controller) controller.abort(); }    
  }, []);

  const handleToggleSystem = (system: System) => {
    if(filter.systems.find(s => s.id == system.id)) {
      filter.setSystems(filter.systems.filter(s => s.id != system.id));
    } else {
      filter.setSystems([system, ...filter.systems]);
    }
  }

  const handleChangeQ = (value: string) => setQ(value);

  const handleChangeOrder = (value: IOrder) => setOrder(value);

  const getTitle = () => {
    return (
      <>
        <div>Systems</div>
        <div>{systems != null && <Counter size="mini">{systems.length}</Counter>}</div>
      </>
    );
  }

  const filteredSystems = systems ? systems
    .filter(system => system.name.toLowerCase().includes((q ?? "").toLowerCase()))
    .sort(order.sort) : [];

  if(!props.open) return null;

  return (
    <div className={props.className}>
      <DrawerHeader title={getTitle()} justify/>
      {systems == null && <Loader/>}
      {systems != null && <>
        <DrawerOptions>
          <Input fluid clearable type="text" placeholder="Search" value={q} onChange={handleChangeQ}>
            <Icon name="search"/>
          </Input>
          <Dropdown fluid data={ORDERS} value={order} label={(item: IOrder) => item.name} placeholder="Order" onChange={handleChangeOrder}>
            <Dropdown.Column>{(item: IOrder) => item.name}</Dropdown.Column>
          </Dropdown>
        </DrawerOptions>
        <Scroller>
          {filteredSystems.length == 0 && <DrawerNodata/>}
          {props.open && filteredSystems
            .map(system => {
            return <SystemCard 
              key={system.id} 
              system={system} 
              active={!!filter.systems.find(s => s.id == system.id)}
              onToggle={() => handleToggleSystem(system)}/>
          })}
        </Scroller>
      </>}
    </div>
  );
}

const SystemsTray = styled(SystemsTrayBase)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Scroller = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 16px;
`

export { SystemsTray }
