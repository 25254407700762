import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const IconBarBase = (props: IProps) =>
  <div className={props.className}>
    {props.children}
  </div>

const IconBar = styled(IconBarBase)`
  position: relative;
  width: 80px;
  display: flex;
  z-index: 200;
  flex-direction: column;
  align-items: center;
  background: #EDF2F4;
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  justify-content: space-between;
  border-right: solid 1px #aaa;
`

export { IconBar }
