import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Station } from '../api/models';
import { Icon, IconStyled, Table } from '@independent-software/typeui/controls';

interface IProps {
  className?: string;
  station: Station;
  onClose: () => void;
}

const StationPanelBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Icon name="times" onClick={props.onClose}/>
      <b>STATION</b>
      <Table transparent striped>
        <tbody>
          <tr>
            <td>ID</td><td>{props.station.id}</td>
          </tr>
          <tr>
            <td>Name</td><td>{props.station.name}</td>
          </tr>
          <tr>
            <td>Longitude</td><td>{props.station.longitude}</td>
          </tr>
          <tr>
            <td>Latitude</td><td>{props.station.latitude}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

const StationPanel = styled(StationPanelBase)`
  position: absolute;
  z-index: 100;
  right: 16px;
  top: 65px;
  border: solid 2px #333;
  border-radius: 8px;
  background: #fff;
  width: 305px;
  padding: 8px 8px 8px 8px;
  box-sizing: border-box;
  ${IconStyled} {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`

export { StationPanel }
