import axios from 'axios';
import { App } from '../App';

/**
 * The Api class simplifies calls to Axios. It offers Get, Post, Put, 
 * Delete and Upload methods, which can be typed. 
 * 
 * @example
 * Api.Get<IUser>("user/32", "s8g8w45eg7fdg7fdg7d8t45")
 * .then(res => console.log(res));
 */
class Api {
  private static getHeaders(access_token: string): object {
    let headers = {};
    if(access_token != null) (headers as any).Authorization = `Bearer ${access_token}`;    
    return headers;
  }
  
  /**
   * Perform a GET request of type T at given URL. This method takes care of
   * sending the access token along. If `null` is specified for the access
   * token, no Authorization header is sent.
   * 
   * @param url URL to retrieve
   * @param access_token Optional access token. Set to null for no access token.
   * @param signal Optional abort signal
   * @returns Promise
   */
  static async Get<T> (url: string, access_token: string, signal?: AbortSignal): Promise<T> {
    const response = await axios.get<T>(App.config.apiURL + url, {
      signal: signal,
      headers: this.getHeaders(access_token)
    });
    return response.data;
  }

  /**
   * Perform a POST request of type T at given URL. This method takes care of
   * sending the access token along. If `null` is specified for the access
   * token, no Authorization header is sent.
   * 
   * @param url URL to access
   * @param request Object with POST information
   * @param access_token Optional access token. Set to null for no access token.
   * @param signal Optional abort signal
   * @returns Promise
   */
  static async Post<T>(url: string, request: any, access_token: string, signal?: AbortSignal): Promise<T> {
    const response = await axios.post<T>(App.config.apiURL + url, request, {
      signal: signal,
      headers: this.getHeaders(access_token)
    });
    return response.data;
  }  

  /**
   * Perform a PUT request of type T at given URL. This method takes care of
   * sending the access token along. If `null` is specified for the access
   * token, no Authorization header is sent.
   * 
   * @param url URL to access
   * @param request Object with PUT information
   * @param access_token Optional access token. Set to null for no access token.
   * @param signal Optional abort signal
   * @returns Promise
   */
  static async Put<T>(url: string, request: any, access_token: string, signal?: AbortSignal): Promise<T> {
    const response = await axios.put<T>(App.config.apiURL + url, request, {
      signal: signal,
      headers: this.getHeaders(access_token)
    });
    return response.data;
  }  

  /**
   * Perform a DELETE request of type T at given URL. This method takes care of
   * sending the access token along. If `null` is specified for the access
   * token, no Authorization header is sent.
   * 
   * @param url URL to access
   * @param access_token Optional access token. Set to null for no access token.
   * @param signal Optional abort signal
   * @returns Promise
   */
  static async Delete<T>(url: string, access_token: string, signal?: AbortSignal): Promise<T> {
    const response = await axios.delete<T>(App.config.apiURL + url, {
      signal: signal,
      headers: this.getHeaders(access_token)
    });
    return response.data;
  }

  /**
   * Perform a POST request of type T at given URL. This request sends 
   * `FormData`, and sets the form-data header. This method takes care of
   * sending the access token along. If `null` is specified for the access
   * token, no Authorization header is sent.
   * 
   * @param url URL to access
   * @param formData `FormData` object to upload
   * @param access_token Optional access token. Set to null for no access token.
   * @param signal Optional abort signal
   * @returns Promise
   */
  static async Upload<T>(url: string, formData: FormData, access_token: string, signal?: AbortSignal): Promise<T> {
    const response = await axios.post<T>(App.config.apiURL + url, formData, {
      signal: signal,
      headers: { ...this.getHeaders(access_token), "Content-Type": "multipart/form-data" }
    });
    return response.data;
  }
}

export { Api }
