import { Url } from "../../helpers/Url";
import { Api } from "../Api";
import { ListResponse, Station, System } from "../models";

class StationApi {
  /**
   * Retrieve list of parameters.
   */
  static list(access_token: string, options?: { systems?: System[] }, signal?: AbortSignal): Promise<ListResponse<Station>> {
    options = options || {};

    const url = new Url(`stations`);
    if(options.systems) {
      url.append('system[]', `in-${options.systems.map(s => s.id).join(',')}`);
    }
    return Api.Get<ListResponse<Station>>(url.toString(), access_token, signal);
  }
}

export { StationApi }
