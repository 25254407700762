import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Hint } from '@independent-software/mapbox-ext/controls/buttons/Hint';

interface IProps {
  /** @ignore */
  className?: string;
}

const SelectSystemHintBase = (props: IProps) => {
  return (
    <Hint side='left' offset={20} wobble>
      <div className={props.className}>
        No system selected.
      </div>
    </Hint>
  );
}

const SelectSystemHint = styled(SelectSystemHintBase)`
  font-size: 12px;
`

export { SelectSystemHint }
