import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { lighten } from '@independent-software/typeui/helper/lighten';

interface IProps {
  /** @ignore */
  className?: string;
  /** Fired when button is clicked. */
  onClick: (e?: React.MouseEvent) => void;
}

const AddColumnButtonBase = (props: IProps) => {
  const handleClick = (e?: React.MouseEvent) => {
    // Do not allow click to reach TableHeaderCell.
    e.stopPropagation();
    props.onClick(e);
  }

  return (
    <div className={props.className} title="Add column" onClick={handleClick}>
      <svg viewBox="0 0 100 100">
        <use xlinkHref={"spritemap.svg#plus"}></use>
      </svg>
    </div>
  );
}

const AddColumnButton = styled(AddColumnButtonBase)`
  display:     flex;
  box-sizing:  border-box;
  width:       17px;
  height:      17px;
  padding:     3px;
  background-color: ${p => p.theme.primaryColor};
  transition: background-color ease-in-out 150ms;
  border-radius: 50%;
  cursor: pointer;
  
  &:hover {
    background-color: ${p => lighten(0.1, p.theme.primaryColor)};
  }
  svg {
    fill: #fff;
  }
`

export { AddColumnButton }

