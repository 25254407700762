import type { CircleLayer } from 'react-map-gl';
import { CLUSTER_OPACITY_END, CLUSTER_OPACITY_START, CLUSTER_SIZE, CLUSTER_SIZE_STEP, CLUSTER_STROKE_COLOR } from './ClusterConstants';

const ClustersInnerStrokeHoverLayer: CircleLayer = {
  id: "clusters-inner-stroke-hover",
  type: "circle",
  paint: {
    "circle-radius": [
      "+", 
        ["*", 
          [ "log10", [ "get", "point_count" ] ], 
          CLUSTER_SIZE_STEP], 
      CLUSTER_SIZE + 4
    ],
    "circle-opacity": 0,
    "circle-color": "transparent",
    "circle-stroke-color": CLUSTER_STROKE_COLOR,
    "circle-stroke-width": 2,  
  }
};

export { ClustersInnerStrokeHoverLayer }
