import * as React from 'react';
import { useMap, Marker } from 'react-map-gl';

import { System } from '../api/models';
import { SystemMarker } from './SystemMarker';
import { Rect } from '../helpers/Rect';

interface IProps {
  systems: System[];
}

interface ISystemProxy {
  systems: System[];
  box: Rect;
}

const MIN_BOX_SIZE = 50;
const BOX_PADDING = 30;

const SystemMarkers = (props: IProps) => {
  const map = useMap();

  const getRect = (system: System): Rect => {
    const { x: left, y: top } = map.current.project({ lng: system.minlng, lat: system.maxlat});
    const { x: right, y: bottom } = map.current.project({ lng: system.maxlng, lat: system.minlat});
    const r = new Rect(left, top, right-left, bottom-top);
    return r.minWidth(MIN_BOX_SIZE).minHeight(MIN_BOX_SIZE).pad(BOX_PADDING);
  }

  const filterSystems = (systems: System[]): ISystemProxy[] => {
    let proxies: ISystemProxy[] = [];

    systems.forEach(system => {
      let box = getRect(system);
      // Find boxes that this system overlaps with, and 
      // a) join these boxes together;
      // b) add the system to the whole.
      const overlappingProxies = proxies.filter(p => p.box.pad(BOX_PADDING).intersect(box.pad(BOX_PADDING)));
      if(overlappingProxies.length > 0) {
        // Remove overlapping proxies from proxies list.
        proxies = proxies.filter(p => !overlappingProxies.includes(p));
        box = [box, ...overlappingProxies.map(p => p.box)].reduce((a,b) => a.union(b));
        let systems = [system];
        overlappingProxies.forEach(p => {
          systems = [...systems, ...p.systems];
        });
        proxies.push({
          box: box,
          systems: systems
        });
      } 
      // No overlaps. Create a new proxy.
      else {
        proxies.push({
          box: box,
          systems: [system]
        });
      }
    });

    return proxies;
  }
  
  const proxies = React.useMemo(() => filterSystems(props.systems), [props.systems, map.current.getZoom()]);

  if(map.current.getZoom() >= 8) return null;

  return proxies.map((p, index) => 
    <Marker 
      key={index}
      longitude={p.systems.map(s => s.avglng).reduce((a, b) => a + b) / p.systems.length}
      latitude={p.systems.map(s => s.avglat).reduce((a, b) => a + b) / p.systems.length}
    >
      <SystemMarker zoom={map.current.getZoom()} systems={p.systems} width={p.box.width} height={p.box.height}/>
    </Marker>      
  );
}

export { SystemMarkers }
