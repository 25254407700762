import type { FillLayer } from 'react-map-gl';

const VisualizationFillLayer: FillLayer = {
  id: 'visualization-fill',
  type: 'fill',
  paint: {
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'v'],
      25.2,
      'black',
      27.4,
      'white'
    ],
    'fill-opacity': 0.75,
    'fill-outline-color': 'transparent'
  }
};

export { VisualizationFillLayer }
