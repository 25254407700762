import type { CircleLayer } from 'react-map-gl';
import { CLUSTER_STROKE_COLOR, CLUSTER_OPACITY_END, CLUSTER_OPACITY_START, CLUSTER_SIZE, CLUSTER_SIZE_STEP } from './ClusterConstants';

const ClustersInnerStrokeLayer: CircleLayer = {
  id: "clusters-inner-stroke",
  type: "circle",
  paint: {
    "circle-radius": [
      "+", 
        ["*", 
          [ "log10", [ "get", "point_count" ] ], 
          CLUSTER_SIZE_STEP], 
      CLUSTER_SIZE + 4
    ],
    "circle-opacity": 0,
    "circle-color": "transparent",
    "circle-stroke-color": CLUSTER_STROKE_COLOR,
    "circle-stroke-width": 2,
    'circle-stroke-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0, 0,
      CLUSTER_OPACITY_START, 0,
      CLUSTER_OPACITY_END, 0.75,
    ],     
  }
};

export { ClustersInnerStrokeLayer }

