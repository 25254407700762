import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Message } from '@independent-software/typeui/controls';
import { Number } from '@independent-software/typeui/formatters/Number';

import { Result } from '../../api/models';
import { FilterContext } from '../../contexts/FilterContext';
import { Table } from '../../controls/Table';
import { ResultApi } from '../../api/services/ResultApi';
import { Loader } from '../../controls';

interface IProps {
}

const ResultsTable = (props: IProps) => {
  const filter = React.useContext(FilterContext);
  const [results, setResults] = React.useState<Result[]>(null);

  //
  // Retrieve results.
  // 
  React.useEffect(() => {
    const controller = new AbortController();
    if(!filter.parameter) {
      setResults([]);
      return;
    }
    ResultApi.list(null, { parameter: filter.parameter, systems: filter.systems}, controller.signal)
    .then(res => {
      setResults(res.data);
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    });    
    return () => { if(controller) controller.abort(); }        
  }, [filter.parameter, filter.systems]);

  if(!filter.parameter) {
    return (<Spacer>
      <Message type='info'>Please select a <b>parameter</b> in order to show results.</Message>
    </Spacer>);
  }

  if(filter.systems.length == 0) {
    return (<Spacer>
      <Message type='info'>Please select a <b>system</b> in order to show results.</Message>
    </Spacer>);    
  }

  if(results === null) {
    return <Loader/>
  }

  const getFilter = () => {
    return (<>
      <div style={{margin: "16px"}}>
        Filter goes here.
      </div>
    </>);
  }

  return (
    <>
      <Table data={results} striped hover grid sort="value" filter={getFilter()}>
        <Table.Column name="Value" sort="value" align="right">{(item: Result) => <Number value={item.value} decimals={2}/>}</Table.Column>
        <Table.Column name="Date" sort="sample.date">{(item: Result) => item.sample.date}</Table.Column>
        <Table.Column name="Station" sort="station.name">{(item: Result) => item.sample.station.name}</Table.Column>
      </Table>
    </>
  );
}

const Spacer = styled.div`
  margin: 16px;
`

export { ResultsTable }
