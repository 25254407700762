import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { IBucket } from '../Area/Bucketer';
import { Bar } from './Bar';
import { Label } from './Label';

interface IProps {
  className?: string;
  bars: IBucket[];
  barFormatter: (d: Date) => string;
  labels: IBucket[];
  labelFormatter: (d: Date) => string;
  zoom: number;
}

const GridBase = (props: IProps) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const sliderRef = React.useRef<HTMLDivElement>(null);

  /* const position = React.useRef<number>(null); // Slider coordinate (fraction 0..1) that's in the middle of the wrapper.
  React.useEffect(() => {
    const wrapperWidth = wrapperRef.current.clientWidth;
    const sliderWidth = sliderRef.current.clientWidth;
    if(position.current != null) {
      //wrapperRef.current.scrollLeft = position.current * sliderWidth - wrapperWidth/2; 
    }
    position.current = (wrapperRef.current.scrollLeft + wrapperWidth / 2) / sliderWidth;
    console.log(sliderWidth, wrapperRef.current.scrollLeft);
    console.log("Storing", position.current);
  }, [props.zoom]); */

  const maxValue = Math.max(...props.bars.map(b => b.count))
  return (
    <div className={props.className} ref={wrapperRef}>
      <div ref={sliderRef}>
         {props.bars.map(b => <Bar key={b.start} bucket={b} maxCount={maxValue} formatter={props.barFormatter}/>)}
         {props.labels.map(b => <Label key={b.start} bucket={b} formatter={props.labelFormatter}/>)}
       </div>
    </div>
  );
}

const Grid = styled(GridBase)`
  position: relative;
  flex: 1;
  background: transparent;
  overflow-x: scroll;
  margin: 3px;

  & > div {
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 15px);
    display: grid;
    grid-auto-columns: ${p => 1 * p.zoom}px;
    grid-template-rows: 1fr 18px;
    grid-row-gap: 2px;
  }
`

export { Grid }
